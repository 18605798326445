import React from 'react';
// import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames/bind';
// import vector from 'assets/images/event/Vector (13).png';
import speaker1 from 'assets/images/speakerpage/speaker1.png';
// import speaker2 from 'assets/images/speakerpage/speaker2.png';
import speaker3 from 'assets/images/speakerpage/speaker3.png';
import speaker4 from 'assets/images/speakerpage/speaker4.png';
import speaker5 from 'assets/images/speakerpage/speaker5.png';
// import speaker6 from 'assets/images/speakerpage/speaker6.png';
import speaker8 from 'assets/images/speaker/speakerupdate/speak8.png';
import speaker9 from 'assets/images/speaker/speakerupdate/speak9.png';
import speaker10 from 'assets/images/speaker/speakerupdate/speak10.png';
import speaker11 from 'assets/images/speaker/speakerupdate/speak11.png';
import speaker12 from 'assets/images/speaker/speakerupdate/speak12.png';
import speaker13 from 'assets/images/speaker/speakerupdate/speak13.png';
// import speaker14 from 'assets/images/speaker/speakerupdate/speak14.png';
import speaker15 from 'assets/images/speaker/speakerupdate/speak15.png';
import speaker16 from 'assets/images/speaker/speakerupdate/speak16.png';
// import speaker17 from 'assets/images/speaker/speakerupdate/speak17.png';
import speaker18 from 'assets/images/speaker/speakerupdate/speak18.png';
import speaker19 from 'assets/images/speaker/speakerupdate/speak19.png';
import speaker20 from 'assets/images/speaker/speakerupdate/speak20.png';
import speaker21 from 'assets/images/speaker/speakerupdate/speak21.png';
import speaker22 from 'assets/images/speaker/speakerupdate/speak22.png';
import speaker23 from 'assets/images/speaker/speakerupdate/speak23.png';
// import speaker24 from 'assets/images/speaker/speakerupdate/speak24.png';
import speaker25 from 'assets/images/speaker/speakerupdate/speak25.png';
import speaker26 from 'assets/images/speaker/speakerupdate/speak26.png';
import speaker27 from 'assets/images/speaker/speakerupdate/speak27.png';
import speaker28 from 'assets/images/speaker/speakerupdate/speak28.png';
import speaker29 from 'assets/images/speaker/speakerupdate/speak29.png';
import speaker30 from 'assets/images/speaker/speakerupdate/speak30.png';
import speaker31 from 'assets/images/speaker/speakerupdate/speak31.png';
import speaker32 from 'assets/images/speaker/speakerupdate/speak32.png';
import speaker33 from 'assets/images/speaker/speakerupdate/speak33.png';
import speaker34 from 'assets/images/speaker/speakerupdate/speak34.png';
import speaker35 from 'assets/images/speaker/speakerupdate/speak35.png';
import speaker36 from 'assets/images/speaker/speakerupdate/speak36.png';
import speaker37 from 'assets/images/speaker/speakerupdate/speak37.png';
import speaker38 from 'assets/images/speaker/speakerupdate/speak38.png';

import styles from './Speaker.module.scss';

const cx = classNames.bind(styles);

function Speaker() {
  const data = [
    {
      id: '1',
      image: speaker19,
      name: 'Mr. Bernard L’Allier',
      desc: 'President, Sales',
      company: ' Cloud4C ',
    },
    {
      id: '2',
      image: speaker21,
      name: 'Ms. Carol Cheung',
      desc: 'Venture Partner',
      company: ' Cocoon Capital ',
    },
    {
      id: '3',
      image: speaker20,
      name: 'Mr. Chaitanya Reddy',
      desc: 'Country Director – CLMV Markets',
      company: '  Cloud4C',
    },
    {
      id: '4',
      image: speaker13,
      name: 'Ms. Clara Lim',
      desc: 'Managing Director',
      company: 'Wiz.ai',
    },
    {
      id: '5',
      image: speaker3,
      name: 'Mr. Cuong Dang',
      desc: 'Founder &',
      desc2: 'Executive Board Director',
      company: 'Forbes Vietnam',
    },
    {
      id: '6',
      image: speaker29,
      name: 'Mr. Chau Chieu Phuc',
      desc: 'Engagement Manager',
      company: 'Investment & Business',
      company2: 'Partners',
    },
    {
      id: '7',
      image: speaker8,
      name: 'Mr. Daryl Chung',
      desc: 'Co-founder & BD Director',
      company: 'JDI',
    },
    {
      id: '8',
      image: speaker30,
      name: 'Mr. Doan Huu Duc',
      desc: ' Founder and Managing Partner',
      company: 'Vietnam Consulting GroupI',
    },
    {
      id: '9',
      image: speaker9,
      name: 'Mr. Frank Lee',
      desc: 'Founder & Managing Director',
      company: 'JDI',
    },
    {
      id: '10',
      image: speaker18,
      name: 'Mr. Himanshu Saluja',
      desc: 'APJ Sales Strategy Specialist',
      desc2: 'Data Center & AI',
      company: '  Intel Technology Asia ',
    },
    {
      id: '11',
      image: speaker31,
      name: 'Mr. Hussein Sulaiman',
      desc: 'Investment Principal',
      company: '  JDI ',
    },
    // {
    //   id: '9',
    //   image: speaker17,
    //   name: 'Mr. Jason Pham',
    //   desc: 'Project Manager and',
    //   desc2: '  Partnership Developer',
    //   company: 'Startup Vietnam Foundation',
    // },
    {
      id: '12',
      image: speaker5,
      name: 'Dr. Le Hoanh Su',
      desc: 'Dean, Faculty of',
      desc2: 'Information Systems',
      company: 'University of Economics ',
      company2: 'and Law',
    },
    {
      id: '13',
      image: speaker25,
      name: 'Dr. Leslie Teo',
      desc: 'Senior Director of',
      desc2: 'AI Products',
      company: '  AI Singapore',
    },
    {
      id: '14',
      image: speaker32,
      name: 'Ms. Linh Nguyen',
      desc: 'Representative',
      company: ' Meta Inc.',
    },
    {
      id: '15',
      image: speaker33,
      name: 'Mr. Mauro Figueiredo',
      desc: 'Former Director, MarTech &',
      desc2: 'Center of Excellence',
      company: ' Techcombank',
    },
    {
      id: '16',
      image: speaker10,
      name: 'Dr. Ng Aik Beng',
      desc: 'Regional Manager',
      company: '  NVIDIA ',
      company2: 'AI Technology Center',
    },
    {
      id: '17',
      image: speaker1,
      name: 'Dr. Nguyen Kim Anh',
      desc: 'Chief Product Officer',
      company: 'VinBigData',
    },

    {
      id: '18',
      image: speaker12,
      name: 'Ms. Nhu Le',
      desc: ' Executive Director & Head of Technology Strategy',
      company: 'Standard Chartered Bank',
    },
    {
      id: '19',
      image: speaker26,
      name: 'Ms. Nicole Zhu',
      desc: ' Co-founder',
      company: 'Homebrew Computer Company',
    },
    {
      id: '20',
      image: speaker22,
      name: 'Mr. Oliver Tan',
      desc: ' Co-Founder & CEO',
      company: 'ViSenze',
    },
    {
      id: '21',
      image: speaker27,
      name: 'Mr. Phung Viet Thang',
      desc: ' Country Manager',
      company: "Intel Vietnam's Sales",
      company2: 'Marketing and ',
      company3: 'Communications Group',
    },
    {
      id: '22',
      image: speaker34,
      name: ' Dr. Quan Le',
      desc: ' Senior Faculty of Engineering',
      company: 'Fulbright University Vietnam',
    },
    {
      id: '23',
      image: speaker16,
      name: ' Ms. Rachel Nguyen',
      desc: 'Chief Digital & Technology Officer',
      company: 'CIMB Vietnam',
    },
    {
      id: '24',
      image: speaker23,
      name: 'Ms. Ruby Nguyen',
      desc: 'Founder & CEO',
      company: 'Curieous',
    },
    {
      id: '25',
      image: speaker28,
      name: 'Ms. Rebecca Joy',
      desc: 'CEO/Founder',
      company: 'Saigon A.I.',
    },
    {
      id: '26',
      image: speaker4,
      name: 'Mr. Sa Chu Tran',
      desc: 'Chief Executive Officer',
      company: '  Vietnam Basketball ',
      company2: 'Association',
    },
    // {
    //   id: '22',
    //   image: speaker24,
    //   name: 'Mr. Sohun Bae',
    //   desc: 'Principal',
    //   company: 'AVV',
    // },
    {
      id: '27',
      image: speaker35,
      name: 'Ms. Thuy Hoang',
      desc: 'Co-Pioneer & COO',
      company: 'The Sentry',
    },
    {
      id: '28',
      image: speaker15,
      name: 'Ms. Tran Phuong Chi',
      desc: 'Deputy CEO',
      company: 'Phong Vu Trading Service Corporation',
    },
    {
      id: '29',
      image: speaker36,
      name: 'Ms. Thai Van Linh',
      desc: 'Chief Executive Officer',
      company4: 'Skills Bridge',
      desc3: 'Senior Advisor',
      company: 'Openspace Venture',
    },
    {
      id: '30',
      image: speaker11,
      name: 'Mr. Trung V. Nguyen',
      desc: 'Co-founder & Deputy CEO',
      company: '  KDI Education',
    },
    {
      id: '31',
      image: speaker37,
      name: ' Ms. Vi Ton',
      desc: 'Founder and CEO',
      company: ' Beyond Creative Agency',
    },
    {
      id: '32',
      image: speaker38,
      name: '  Ph.D. William H Nguyen',
      desc: 'Founder & CEO',
      company: 'Quickom',
    },
  ];

  // const isDesktop = useMediaQuery({
  //   query: '(min-width: 1024px)',
  // });
  // const [currentPage, setCurrentPage] = useState(1);
  // const postsPerPage = isDesktop ? 15 : 6;
  // const pages = [];
  // for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i += 1) {
  //   pages.push(i);
  // }

  // const lastPostIndex = currentPage * postsPerPage;
  // const firstPostIndex = lastPostIndex - postsPerPage;
  // const currentPosts = data.slice(firstPostIndex, lastPostIndex);

  // const handleClick = (page) => {
  //   console.log(`Page clicked: ${page}`);
  //   setCurrentPage(page);
  // };
  return (
    <div className={cx('Component')}>
      <div className={cx('Content')}>
        <div className={cx('Text')}>OUR SPEAKERS</div>
      </div>
      <div className={cx('Wrapper')}>
        <div className={cx('Contain')}>
          {data.map((item) => (
            <div key={item.id} className={cx('ContainBox')}>
              <img src={item.image} alt="" className={cx('Image')} />
              <div className={cx('Content')}>
                <p className={cx('Name')}>{item.name}</p>

                <p className={cx('Desc')}>{item.desc}</p>
                <p className={cx('Desc')}>{item.desc2}</p>
                <p className={cx('Company')}>{item.company4}</p>
                <p className={cx('Desc')}>{item.desc3}</p>
                <p className={cx('Company')}>{item.company}</p>
                <p className={cx('Company')}>{item.company2}</p>
                <p className={cx('Company')}>{item.company3}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className={cx('Pagination')}>
          {pages.map((page) => (
            <button
              key={page}
              type="button"
              onClick={() => handleClick(page)}
              className={cx('page', { active: currentPage === page })}
            >
              {String(page).padStart(2, '0')}
            </button>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Speaker;
