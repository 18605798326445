import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

// import avatar0 from 'assets/images/speaker/speaker-0.png';
// import avatar1 from 'assets/images/speaker/speaker-1.png';
// import avatar2 from 'assets/images/speaker/speaker-2.png';
// import avatar3 from 'assets/images/speaker/speaker-3.png';
// import avatar4 from 'assets/images/speaker/speaker-4.png';
// import avatar5 from 'assets/images/speaker/speaker-5.png';
import speaker1 from 'assets/images/speaker/speakerupdate/speaker1.png';
// import speaker2 from 'assets/images/speaker/speakerupdate/speaker2.png';
// import speaker3 from 'assets/images/speaker/speakerupdate/speaker3.png';
import speaker4 from 'assets/images/speaker/speakerupdate/speaker4.png';
import speaker5 from 'assets/images/speaker/speakerupdate/speaker5.png';
import speaker6 from 'assets/images/speaker/speakerupdate/speaker6.png';
// import speaker7 from 'assets/images/speaker/speakerupdate/speak7.png';
import speaker8 from 'assets/images/speaker/speakerupdate/speak8.png';
import speaker9 from 'assets/images/speaker/speakerupdate/speak9.png';
import speaker10 from 'assets/images/speaker/speakerupdate/speak10.png';
import speaker11 from 'assets/images/speaker/speakerupdate/speak11.png';
import speaker12 from 'assets/images/speaker/speakerupdate/speak12.png';
import speaker13 from 'assets/images/speaker/speakerupdate/speak13.png';
// import speaker14 from 'assets/images/speaker/speakerupdate/speak14.png';
import speaker15 from 'assets/images/speaker/speakerupdate/speak15.png';
import speaker16 from 'assets/images/speakerpage/speak16.png';
// import speaker17 from 'assets/images/speakerpage/speak17.png';
import speaker18 from 'assets/images/speakerpage/speak18.png';
import speaker19 from 'assets/images/speakerpage/speak19.png';
import speaker20 from 'assets/images/speakerpage/speak20.png';
import speaker21 from 'assets/images/speakerpage/speak21.png';
import speaker22 from 'assets/images/speakerpage/speak22.png';
// import speaker23 from 'assets/images/speakerpage/speak23.png';
import speaker24 from 'assets/images/speakerpage/speak24.png';
import speaker25 from 'assets/images/speaker/speakerupdate/speak25.png';
import speaker26 from 'assets/images/speaker/speakerupdate/speak26.png';
import speaker27 from 'assets/images/speaker/speakerupdate/speak27.png';
import speaker28 from 'assets/images/speaker/speakerupdate/speak28.png';
import speaker29 from 'assets/images/speaker/speakerupdate/speak29.png';
import speaker30 from 'assets/images/speaker/speakerupdate/speak30.png';
import speaker31 from 'assets/images/speaker/speakerupdate/speak31.png';
import speaker32 from 'assets/images/speaker/speakerupdate/speak32.png';
import speaker33 from 'assets/images/speaker/speakerupdate/speak33.png';
import speaker34 from 'assets/images/speaker/speakerupdate/speak34.png';
import speaker35 from 'assets/images/speaker/speakerupdate/speak35.png';
import speaker36 from 'assets/images/speaker/speakerupdate/speak36.png';
import speaker37 from 'assets/images/speaker/speakerupdate/speak37.png';
import speaker38 from 'assets/images/speaker/speakerupdate/speak38.png';

import background from 'assets/images/speaker/background.png';
// import backgroundCircle from 'assets/images/speaker/bg_circle.png';
import back from 'assets/images/speaker/back.svg';
import next from 'assets/images/speaker/next.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './Speaker.module.scss';

const cx = classNames.bind(styles);
function Speaker() {
  return (
    <div id="speakers" className={cx('Component')}>
      <img className={cx('Background')} src={background} alt="background" />
      <div className={cx('Content')}>
        <div className={cx('Title')}>Guest Speakers & Moderators</div>

        <div className={cx('Card')}>
          <Swiper
            navigation={{
              nextEl: '#backtopic',
              prevEl: '#nexttopic',
            }}
            modules={[Autoplay, Navigation]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            // loop
            spaceBetween={32}
            className={cx('Swiper')}
            slidesPerView={3}
          >
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker19}
                  alt="Jeremy Ang"
                />

                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Bernard L’Allier</div>
                  <div className={cx('Desc')}>President, Sales</div>
                  <div className={cx('Role')}>Cloud4C</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker21}
                  alt="Jeremy Ang"
                />

                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Carol Cheung</div>
                  <div className={cx('Desc')}>Venture Partner</div>
                  <div className={cx('Role')}>Cocoon Capital</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker20}
                  alt="Jeremy Ang"
                />

                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Chaitanya Reddy</div>
                  <div className={cx('Desc')}>
                    Country Director – CLMV Markets
                  </div>
                  <div className={cx('Role')}>Cloud4C</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker13}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Clara Lim</div>
                  <div className={cx('Desc')}>Managing Director</div>
                  <div className={cx('Role')}>Wiz.ai</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img className={cx('Avatar')} src={speaker5} alt="" />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Cuong Dang</div>
                  <div className={cx('Desc')}>Founder &</div>
                  <div className={cx('Desc')}>Executive Board Director</div>
                  <div className={cx('Role')}>Forbes Vietnam</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img className={cx('Avatar')} src={speaker29} alt="" />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Chau Chieu Phuc</div>
                  <div className={cx('Desc')}>Engagement Manager</div>
                  <div className={cx('Role')}>Investment & Business</div>
                  <div className={cx('Role')}>Partners</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker8}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Daryl Chung</div>
                  <div className={cx('Desc')}>Co-founder & BD Director</div>
                  <div className={cx('Role')}>JDI</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker30}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Doan Huu Duc</div>
                  <div className={cx('Desc')}>Founder and Managing Partner</div>
                  <div className={cx('Role')}>Vietnam Consulting Group</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker9}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Frank Lee</div>
                  <div className={cx('Desc')}>Founder & Managing Director</div>
                  <div className={cx('Role')}>JDI</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img className={cx('Avatar')} src={speaker18} alt="" />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Himanshu Saluja</div>
                  <div className={cx('Desc')}>
                    APJ Sales Strategy Specialist Data Center & AI
                  </div>
                  <div className={cx('Role')}>Intel Technology Asia</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker31}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Hussein Sulaiman</div>
                  <div className={cx('Desc')}>Investment Principal</div>
                  <div className={cx('Role')}>JDI</div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker17}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Jason Pham</div>
                  <div className={cx('Desc')}>Project Manager and</div>
                  <div className={cx('Desc')}> Partnership Developer</div>
                  <div className={cx('Role')}>Startup Vietnam Foundation</div>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker6}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Dr. Le Hoanh Su</div>
                  <div className={cx('Desc')}>Dean, Faculty of</div>
                  <div className={cx('Desc')}>Information Systems</div>
                  <div className={cx('Role')}>
                    University of Economics and Law
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker25}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Leslie Teo</div>
                    <div className={cx('Desc')}>Senior Director of</div>
                    <div className={cx('Desc')}> AI Products</div>
                    <div className={cx('Role')}>AI Singapore</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker32}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Linh Nguyen</div>
                    <div className={cx('Desc')}>Representative</div>
                    <div className={cx('Role')}>Meta Inc.</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker33}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Mauro Figueiredo</div>
                    <div className={cx('Desc')}>Former Director, MarTech &</div>
                    <div className={cx('Desc')}> Center of Excellence</div>
                    <div className={cx('Role')}>Techcombank</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker10}
                  alt="Jeremy Ang"
                />

                <div className={cx('Info')}>
                  <div className={cx('Name')}>Dr. Ng Aik Beng</div>
                  <div className={cx('Desc')}>Regional Manager</div>
                  <div className={cx('Role')}>
                    NVIDIA
                    <br />
                    AI Technology Center
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img className={cx('Avatar')} src={speaker1} alt="Jeremy Ang" />

                <div className={cx('Info')}>
                  <div className={cx('Name')}>Dr. Nguyen Kim Anh</div>
                  <div className={cx('Desc')}>Chief Product Officer</div>
                  <div className={cx('Role')}>VinBigData</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker12}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Nhu Le</div>
                  <div className={cx('Desc')}>
                    Executive Director & Head
                    <br />
                    of Technology Strategy
                  </div>
                  <div className={cx('Role')}>Standard Chartered Bank</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker26}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Nicole Zhu</div>
                  <div className={cx('Desc')}>Co-founder</div>
                  <div className={cx('Role')}>Homebrew Computer Company</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker22}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Oliver Tan</div>
                    <div className={cx('Desc')}>Co-Founder & CEO</div>
                    <div className={cx('Role')}>ViSenze</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker27}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Phung Viet Thang</div>
                    <div className={cx('Desc')}>Country Manager</div>
                    <div className={cx('Role')}>
                      Intel Vietnam&apos;s Sales,
                    </div>
                    <div className={cx('Role')}> Marketing and</div>
                    <div className={cx('Role')}>Communications Group</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker34}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Quan Le</div>
                    <div className={cx('Desc')}>
                      Senior Faculty of Engineering
                    </div>
                    <div className={cx('Role')}>
                      Fulbright University Vietnam
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker16}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Rachel Nguyen</div>
                  <div className={cx('Desc')}>
                    Chief Digital & Technology Officer
                  </div>
                  <div className={cx('Role')}>CIMB Vietnam</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker24}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Ruby Nguyen</div>
                    <div className={cx('Desc')}>Founder & CEO</div>
                    <div className={cx('Role')}>Curieous</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker28}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Rebecca Joy</div>
                    <div className={cx('Desc')}>CEO/Founder</div>
                    <div className={cx('Role')}>Saigon A.I.</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker4}
                  alt="Kim Anh Nguyen"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Mr. Sa Chu Tran</div>
                  <div className={cx('Desc')}>Chief Executive Officer</div>
                  <div className={cx('Role')}>
                    Vietnam Basketball Association
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker23}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Sohun Bae</div>
                    <div className={cx('Desc')}>Principal</div>
                    <div className={cx('Role')}>AVV</div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker35}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Thuy Hoang</div>
                    <div className={cx('Desc')}> Co-Pioneer & COO</div>
                    <div className={cx('Role')}>The Sentry</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker15}
                  alt="Dr. Charles Cheung"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Tran Phuong Chi</div>
                  <div className={cx('Desc')}>Deputy CEO</div>
                  <div className={cx('Role')}>
                    Phong Vu Trading Service Corporation
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker36}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Thai Van Linh</div>
                    <div className={cx('Desc')}>Chief Executive Officer</div>
                    <div className={cx('Role')}>Skills Bridge</div>
                    <div className={cx('Desc')}>Senior Advisor</div>
                    <div className={cx('Role')}>Openspace Ventures</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker11}
                  alt="Kim Anh Nguyen"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}> Mr. Trung V. Nguyen</div>
                  <div className={cx('Desc')}>Co-founder & Deputy CEO</div>
                  <div className={cx('Role')}>KDI Education</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker37}
                  alt="Kim Anh Nguyen"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}>Ms. Vi Ton</div>
                  <div className={cx('Desc')}>Founder and CEO</div>
                  <div className={cx('Role')}>Beyond Creative Agency</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('CardItem')}>
                <img
                  className={cx('Avatar')}
                  src={speaker38}
                  alt="Kim Anh Nguyen"
                />
                <div className={cx('Info')}>
                  <div className={cx('Name')}> Ph.D. William H Nguyen</div>
                  <div className={cx('Desc')}>Founder & CEO</div>
                  <div className={cx('Role')}>Quickom</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <img src={back} alt="back" className={cx('Back')} id="backtopic" />
          <img src={next} alt="next" className={cx('Next')} id="nexttopic" />
        </div>

        <div className={cx('WrapperMobile')}>
          <Swiper
            className={cx('SwiperPartner')}
            modules={[Pagination]}
            spaceBetween={32}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            slidesPerGroup={1}
            centeredSlides
          >
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker19}
                    alt="Jeremy Ang"
                  />

                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Bernard L’Allier</div>
                    <div className={cx('Desc')}>President, Sales</div>
                    <div className={cx('Role')}>Cloud4C</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker21}
                    alt="Jeremy Ang"
                  />

                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Carol Cheung</div>
                    <div className={cx('Desc')}>Venture Partner</div>
                    <div className={cx('Role')}>Cocoon Capital</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker20}
                    alt="Jeremy Ang"
                  />

                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Chaitanya Reddy</div>
                    <div className={cx('Desc')}>
                      Country Director – CLMV Markets
                    </div>
                    <div className={cx('Role')}>Cloud4C</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker13}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Clara Lim</div>
                    <div className={cx('Desc')}>Managing Director</div>
                    <div className={cx('Role')}>Wiz.ai</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker5}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Cuong Dang</div>
                    <div className={cx('Desc')}>Founder &</div>
                    <div className={cx('Desc')}>Executive Board Director</div>
                    <div className={cx('Role')}>Forbes Vietnam</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker29}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Chau Chieu Phuc</div>
                    <div className={cx('Desc')}>Engagement Manager</div>
                    <div className={cx('Role')}>Investment & Business</div>
                    <div className={cx('Role')}>Partners</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker8}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Daryl Chung</div>
                    <div className={cx('Desc')}>Co-founder & BD Director</div>
                    <div className={cx('Role')}>JDI</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker30}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Doan Huu Duc</div>
                    <div className={cx('Desc')}>
                      Founder and Managing Partner
                    </div>
                    <div className={cx('Role')}>Vietnam Consulting Group</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker9}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Frank Lee</div>
                    <div className={cx('Desc')}>
                      Founder & Managing Director
                    </div>
                    <div className={cx('Role')}>JDI</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker18}
                    alt="Jeremy Ang"
                  />

                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Himanshu Saluja</div>
                    <div className={cx('Desc')}>
                      APJ Sales Strategy Specialist Data Center & AI
                    </div>
                    <div className={cx('Role')}>Intel Technology Asia</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker31}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Hussein Sulaiman</div>
                    <div className={cx('Desc')}>Investment Principal</div>
                    <div className={cx('Role')}>JDI</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker17}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Jason Pham</div>
                    <div className={cx('Desc')}>Project Manager and</div>
                    <div className={cx('Desc')}> Partnership Developer</div>
                    <div className={cx('Role')}>Startup Vietnam Foundation</div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker6}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Le Hoanh Su</div>
                    <div className={cx('Desc')}>Dean, Faculty of</div>
                    <div className={cx('Desc')}>Information Systems</div>
                    <div className={cx('Role')}>
                      University of Economics and Law
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker25}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Leslie Teo</div>
                    <div className={cx('Desc')}>Senior Director of</div>
                    <div className={cx('Desc')}> AI Products</div>
                    <div className={cx('Role')}>AI Singapore</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker32}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Linh Nguyen</div>
                    <div className={cx('Desc')}>Representative</div>
                    <div className={cx('Role')}>Meta Inc.</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker33}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Mauro Figueiredo</div>
                    <div className={cx('Desc')}>Former Director, MarTech &</div>
                    <div className={cx('Desc')}> Center of Excellence</div>
                    <div className={cx('Role')}>Techcombank</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker10}
                    alt="Jeremy Ang"
                  />

                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Ng Aik Beng</div>
                    <div className={cx('Desc')}>Regional Manager</div>
                    <div className={cx('Role')}>
                      NVIDIA
                      <br />
                      AI Technology Center
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker1}
                    alt="Jeremy Ang"
                  />

                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Nguyen Kim Anh</div>
                    <div className={cx('Desc')}>Chief Product Officer</div>
                    <div className={cx('Role')}>VinBigData</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker12}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Nhu Le</div>
                    <div className={cx('Desc')}>
                      Executive Director & Head
                      <br />
                      of Technology Strategy
                    </div>
                    <div className={cx('Role')}>Standard Chartered Bank</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker26}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Nicole Zhu</div>
                    <div className={cx('Desc')}>Co-founder</div>
                    <div className={cx('Role')}>Homebrew Computer Company</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker22}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Oliver Tan</div>
                    <div className={cx('Desc')}>Co-Founder & CEO</div>
                    <div className={cx('Role')}>ViSenze</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker27}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Phung Viet Thang</div>
                    <div className={cx('Desc')}>Country Manager</div>
                    <div className={cx('Role')}>
                      Intel Vietnam&apos;s Sales,
                    </div>
                    <div className={cx('Role')}> Marketing and</div>
                    <div className={cx('Role')}>Communications Group</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker34}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Dr. Quan Le</div>
                    <div className={cx('Desc')}>
                      Senior Faculty of Engineering
                    </div>
                    <div className={cx('Role')}>
                      Fulbright University Vietnam
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker16}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Rachel Nguyen</div>
                    <div className={cx('Desc')}>
                      Chief Digital & Technology Officer
                    </div>
                    <div className={cx('Role')}>CIMB Vietnam</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker24}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Ruby Nguyen</div>
                    <div className={cx('Desc')}>Founder & CEO</div>
                    <div className={cx('Role')}>Curieous</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker28}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Rebecca Joy</div>
                    <div className={cx('Desc')}>CEO/Founder</div>
                    <div className={cx('Role')}>Saigon A.I.</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker4}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Sa Chu Tran</div>
                    <div className={cx('Desc')}>Chief Executive Officer</div>
                    <div className={cx('Role')}>Vietnam Basketball</div>
                    <div className={cx('Role')}>Association</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            {/* <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker23}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Mr. Sohun Bae</div>
                    <div className={cx('Desc')}>Principal</div>
                    <div className={cx('Role')}>AVV</div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker35}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Thuy Hoang</div>
                    <div className={cx('Desc')}> Co-Pioneer & COO</div>
                    <div className={cx('Role')}>The Sentry</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker15}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Tran Phuong Chi</div>
                    <div className={cx('Desc')}>Deputy CEO</div>
                    <div className={cx('Role')}>
                      Phong Vu Trading Service Corporation
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker36}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}>Ms. Thai Van Linh</div>
                    <div className={cx('Desc')}>Chief Executive Officer</div>
                    <div className={cx('Role')}>Skills Bridge</div>
                    <div className={cx('Desc')}>Senior Advisor</div>
                    <div className={cx('Role')}>Openspace Ventures</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker11}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}> Mr. Trung V. Nguyen</div>
                    <div className={cx('Desc')}>Co-founder & Deputy CEO</div>
                    <div className={cx('Role')}>KDI Education</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker37}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}> Ms. Vi Ton</div>
                    <div className={cx('Desc')}>Founder and CEO</div>
                    <div className={cx('Role')}>Beyond Creative Agency</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cx('Contain')}>
                <div className={cx('CardItem')}>
                  <img
                    className={cx('Avatar')}
                    src={speaker38}
                    alt="Kim Anh Nguyen"
                  />
                  <div className={cx('Info')}>
                    <div className={cx('Name')}> Ph.D. William H Nguyen</div>
                    <div className={cx('Desc')}>Founder & CEO</div>
                    <div className={cx('Role')}>Quickom</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={cx('Btn')}>
          <a
            href="/speaker"
            className={cx('BtnItem')}
            // target="_blank"
            rel="noreferrer"
          >
            See more
          </a>
        </div>
      </div>
    </div>
  );
}

export default Speaker;
