import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

// import logoDiamond from 'assets/images/partners/Layer_1 (13).png';
// import amazon from 'assets/images/partners/amazon.svg';
// import sliver1 from 'assets/images/partners/Frame 480.png';
// import sliver2 from 'assets/images/partners/Frame 488.png';
// import sliver3 from 'assets/images/partners/Frame 489.png';
// import bronze1 from 'assets/images/partners/sliver-1.svg';
// import bronze2 from 'assets/images/partners/sliver-2.svg';
// import bronze3 from 'assets/images/partners/sliver-3.svg';
// import venua from 'assets/images/partners/venue-sponsor.svg';
// import media1 from 'assets/images/partners/media-1.png';
// import media2 from 'assets/images/partners/media-2.png';
// import media3 from 'assets/images/partners/media-3.png';
// import media4 from 'assets/images/partners/media-4.png';
import media5 from 'assets/images/partners/media-5.png';
import media6 from 'assets/images/partners/media-6.png';
import media7 from 'assets/images/partners/media-7.png';
import logoDiamond1 from 'assets/images/sponsor/Group (6).png';
import logoDiamond2 from 'assets/images/sponsor/Group (7).png';
import logoDiamond3 from 'assets/images/sponsor/Group (8).png';

// import partner2 from 'assets/images/partners/media-partner-2.png';
// import partner3 from 'assets/images/partners/media-partner-3.png';
// import partner4 from 'assets/images/partners/media-partner-4.png';
// import partner5 from 'assets/images/partners/media-partner-5.png';
// import partner6 from 'assets/images/partners/media-partner-6.png';
// import partner7 from 'assets/images/partners/media-partner-7.png';
// import partner8 from 'assets/images/partners/media-partner-8.png';
// import partner9 from 'assets/images/partners/media-partner-9.png';
// import partner10 from 'assets/images/partners/media-partner-10.png';
// import partner11 from 'assets/images/partners/media-partner-11.png';
// import partner12 from 'assets/images/partners/media-partner-12.png';
// import partner13 from 'assets/images/partners/media-partner-13.png';
// import partner14 from 'assets/images/partners/media-partner-14.png';
// import partner15 from 'assets/images/partners/media-partner-15.png';
// import partner16 from 'assets/images/partners/media-partner-16.png';
// import partner17 from 'assets/images/partners/media-partner-17.png';
// import partner18 from 'assets/images/partners/media-partner-18.png';
// import partner19 from 'assets/images/partners/media-partner-19.png';
import partner20 from 'assets/images/partners/media-partner-20.png.png';
import partner21 from 'assets/images/partners/media-partner-21.png.png';
import partner22 from 'assets/images/partners/media-partner-22.png.png';
import partner23 from 'assets/images/partners/media-partner-23.png.png';
import partner24 from 'assets/images/partners/media-partner-24.png.png';
import partner25 from 'assets/images/partners/media-partner-25.png';
import partner26 from 'assets/images/partners/media-partner-26.png';
import partner27 from 'assets/images/partners/media-partner-27.png';
import partner28 from 'assets/images/partners/media-partner-28.png';
import partner29 from 'assets/images/partners/media-partner-29.png';
import partner30 from 'assets/images/partners/media-partner-30.png';
import partner31 from 'assets/images/partners/media-partner-31.png';
import partner32 from 'assets/images/partners/media-partner-32.png';
import partner33 from 'assets/images/partners/media-partner-33.png';
import partner34 from 'assets/images/partners/media-partner-34.png';
import partner35 from 'assets/images/partners/media-partner-35.png';
import partner36 from 'assets/images/partners/media-partner-36.png';
// import partner37 from 'assets/images/partners/media-partner-37.png';
import partner38 from 'assets/images/partners/media-partner-38.png';
import partner39 from 'assets/images/partners/media-partner-39.png';
import partner40 from 'assets/images/partners/media-partner-40.png';
// import partner41 from 'assets/images/partners/media-partner-41.png';
import partner42 from 'assets/images/partners/media-partner-42.png';

import partner43 from 'assets/images/partners/media-partner-43.png';
import partner44 from 'assets/images/partners/media-partner-44.png';
import partner45 from 'assets/images/partners/media-partner-45.png';
// import partner46 from 'assets/images/partners/media-partner-46.png';
import partner47 from 'assets/images/partners/media-partner-47.png';
// import partner48 from 'assets/images/partners/media-partner-48.png';
// import partner49 from 'assets/images/partners/media-partner-49.png';
import partner50 from 'assets/images/partners/media-partner-50.png';
import partner51 from 'assets/images/partners/media-partner-51.png';
import partner52 from 'assets/images/partners/media-partner-52.png';
import partner53 from 'assets/images/partners/media-partner-53.png';
import partner54 from 'assets/images/partners/media-partner-54.png';
import partner55 from 'assets/images/partners/media-partner-55.png';
import partner56 from 'assets/images/partners/media-partner-56.png';
import partner57 from 'assets/images/partners/media-partner-57.png';
import partner58 from 'assets/images/partners/media-partner-58.png';
import partner59 from 'assets/images/partners/media-partner-59.png';
import partner60 from 'assets/images/partners/media-partner-60.png';
import partner61 from 'assets/images/partners/media-partner-61.png';
import partner62 from 'assets/images/partners/media-partner-62.png';
import partner63 from 'assets/images/partners/media-partner-63.png';
import partner64 from 'assets/images/partners/media-partner-64.png';
import partner65 from 'assets/images/partners/media-partner-65.png';
import partner66 from 'assets/images/partners/media-partner-66.png';
import partner67 from 'assets/images/partners/media-partner-67.png';
import partner68 from 'assets/images/partners/media-partner-68.png';
import partner69 from 'assets/images/partners/media-partner-69.png';
import partner70 from 'assets/images/partners/media-partner-70.png';
import partner71 from 'assets/images/partners/media-partner-71.png';
import partner72 from 'assets/images/partners/media-partner-72.png.png';
import partner73 from 'assets/images/partners/media-partner-73.png';

import private1 from 'assets/images/Layer_1 (17).png';
import private2 from 'assets/images/Group (12).png';
import private3 from 'assets/images/Artwork (1).png';
import exhibitor1 from 'assets/images/ex1.png';
import exhibitor2 from 'assets/images/ex2.png';
import exhibitor3 from 'assets/images/ex3.png';
import exhibitor4 from 'assets/images/ex4.png';
import exhibitor5 from 'assets/images/ex5.png';
import exhibitor6 from 'assets/images/ex6.png';
import exhibitor7 from 'assets/images/ex7.png';
import exhibitor8 from 'assets/images/ex8.png';
import exhibitor9 from 'assets/images/ex9.png';

import prize1 from 'assets/images/pri1.png';
import prize2 from 'assets/images/pri2.png';
import prize3 from 'assets/images/pri3.png';
import prize4 from 'assets/images/pri4.png';
import prize5 from 'assets/images/pri5.png';

// import ex1 from 'assets/images/partners/exhibitors/ex1.png';
// import ex2 from 'assets/images/partners/exhibitors/ex2.png';
// import ex3 from 'assets/images/partners/exhibitors/ex3.png';
// import ex4 from 'assets/images/partners/exhibitors/ex4.png';
// import ex5 from 'assets/images/partners/exhibitors/ex5.png';
// import ex6 from 'assets/images/partners/exhibitors/ex6.png';
// import ex7 from 'assets/images/partners/exhibitors/ex7.png';
// import ex8 from 'assets/images/partners/exhibitors/ex8.png';
// import ex9 from 'assets/images/partners/exhibitors/ex9.png';

// import bg from 'assets/images/bg-sponsors.svg';
import trans from 'assets/images/partners/Dimensions@2x.png';
import isolation from 'assets/images/partners/Isolation_Mode.png';
import cohost from 'assets/images/cohost2.png';
import cohost2 from 'assets/images/Frame 681 (2).png';
import stra from 'assets/images/partners/Layer_1 (12).png';
import phongvu from 'assets/images/partners/Frame 480 (2).png';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import back from 'assets/images/speaker/back.svg';
import next from 'assets/images/speaker/next.svg';

import styles from './MediaPartner.module.scss';

const cx = classNames.bind(styles);

// const SLIVER = [
//   {
//     id: 1,
//     image: sliver1,
//     classNames: 'ImageSilver1',
//   },
//   {
//     id: 2,
//     image: sliver2,
//     classNames: 'ImageSilver2',
//   },
//   {
//     id: 3,
//     image: sliver3,
//     classNames: 'ImageSilver3',
//   },
// ];

// const Bronze = [
//   {
//     id: 1,
//     image: bronze1,
//   },
//   {
//     id: 2,
//     image: bronze2,
//   },
//   {
//     id: 3,
//     image: bronze3,
//   },
// ];

// const Exhibitor = [
//   {
//     id: 1,
//     image: ex1,
//   },
//   {
//     id: 2,
//     image: ex2,
//   },
//   {
//     id: 3,
//     image: ex3,
//   },
//   {
//     id: 4,
//     image: ex4,
//   },
//   {
//     id: 5,
//     image: ex5,
//   },
//   {
//     id: 6,
//     image: ex6,
//   },
//   {
//     id: 7,
//     image: ex7,
//   },
//   {
//     id: 8,
//     image: ex8,
//   },
//   {
//     id: 9,
//     image: ex9,
//   },
// ];
const Exhibitor = [
  {
    id: 1,
    image: exhibitor6,
  },
  {
    id: 2,
    image: exhibitor4,
  },
  {
    id: 3,
    image: exhibitor7,
  },
  {
    id: 4,
    image: exhibitor8,
  },
  {
    id: 5,
    image: exhibitor2,
  },
  {
    id: 6,
    image: exhibitor9,
  },
  {
    id: 7,
    image: exhibitor3,
  },
  {
    id: 8,
    image: exhibitor1,
  },
  {
    id: 9,
    image: exhibitor5,
  },
];
const Prize = [
  {
    id: 1,
    image: prize1,
  },
  {
    id: 2,
    image: prize2,
  },
  {
    id: 3,
    image: prize3,
  },
  {
    id: 4,
    image: prize4,
  },
  {
    id: 5,
    image: prize5,
  },
];
const Media = [
  // {
  //   id: 1,
  //   image: media1,
  // },
  // {
  //   id: 2,
  //   image: media2,
  // },
  // {
  //   id: 3,
  //   image: media3,
  // },
  // {
  //   id: 4,
  //   image: media4,
  // },
  {
    id: 5,
    image: media5,
  },
  {
    id: 6,
    image: media6,
  },
  {
    id: 7,
    image: media7,
  },
];

const Partner = [
  {
    id: 20,
    image: partner20,
  },
  {
    id: 21,
    image: partner21,
  },
  {
    id: 22,
    image: partner22,
  },
  {
    id: 23,
    image: partner23,
  },
  {
    id: 24,
    image: partner24,
  },
  {
    id: 25,
    image: partner25,
  },
  {
    id: 26,
    image: partner26,
  },
  {
    id: 27,
    image: partner27,
  },
  {
    id: 28,
    image: partner28,
  },
  {
    id: 29,
    image: partner29,
  },
  {
    id: 30,
    image: partner30,
  },
  {
    id: 31,
    image: partner31,
  },
  {
    id: 32,
    image: partner32,
  },
  // {
  //   id: 33,
  //   image: partner33,
  // },
  {
    id: 34,
    image: partner34,
  },
  {
    id: 35,
    image: partner35,
  },
  {
    id: 36,
    image: partner36,
  },
  // {
  //   id: 37,
  //   image: partner37,
  // },
  {
    id: 38,
    image: partner38,
  },
  {
    id: 39,
    image: partner39,
  },
  {
    id: 40,
    image: partner40,
  },
  // {
  //   id: 41,
  //   image: partner41,
  // },
  // {
  //   id: 42,
  //   image: partner42,
  // },
  {
    id: 43,
    image: partner43,
  },
  {
    id: 44,
    image: partner44,
  },
  {
    id: 45,
    image: partner45,
  },
  {
    id: 42,
    image: partner42,
  },
  {
    id: 47,
    image: partner47,
  },
  {
    id: 72,
    image: partner72,
  },
  {
    id: 73,
    image: partner73,
  },
  // {
  //   id: 48,
  //   image: partner48,
  // },
  // {
  //   id: 49,
  //   image: partner49,
  // },
  {
    id: 64,
    image: partner64,
  },
  {
    id: 65,
    image: partner65,
  },
  {
    id: 66,
    image: partner66,
  },
  {
    id: 50,
    image: partner50,
  },
  {
    id: 51,
    image: partner51,
  },
  {
    id: 52,
    image: partner52,
  },
  {
    id: 53,
    image: partner53,
  },
  {
    id: 54,
    image: partner54,
  },
  {
    id: 55,
    image: partner55,
  },
  {
    id: 56,
    image: partner56,
  },
  {
    id: 57,
    image: partner57,
  },
  {
    id: 58,
    image: partner33,
  },
  {
    id: 59,
    image: partner58,
  },
  {
    id: 60,
    image: partner59,
  },
  {
    id: 61,
    image: partner60,
  },
  {
    id: 62,
    image: partner61,
  },
  {
    id: 63,
    image: partner62,
  },
  {
    id: 64,
    image: partner63,
  },
  {
    id: 67,
    image: partner67,
  },
  {
    id: 68,
    image: partner68,
  },
  {
    id: 70,
    image: partner70,
  },
  {
    id: 71,
    image: partner71,
  },
  {
    id: 69,
    image: partner69,
  },
  // {
  //   id: 2,
  //   image: partner2,
  // },
  // {
  //   id: 3,
  //   image: partner3,
  // },
  // {
  //   id: 4,
  //   image: partner4,
  // },
  // {
  //   id: 5,
  //   image: partner5,
  // },
  // {
  //   id: 6,
  //   image: partner6,
  // },
  // {
  //   id: 7,
  //   image: partner7,
  // },
  // {
  //   id: 8,
  //   image: partner8,
  // },
  // {
  //   id: 9,
  //   image: partner9,
  // },
  // {
  //   id: 10,
  //   image: partner10,
  // },
  // {
  //   id: 11,
  //   image: partner11,
  // },
  // {
  //   id: 12,
  //   image: partner12,
  // },
  // {
  //   id: 13,
  //   image: partner13,
  // },
  // {
  //   id: 14,
  //   image: partner14,
  // },
  // {
  //   id: 15,
  //   image: partner15,
  // },
  // {
  //   id: 16,
  //   image: partner16,
  // },
  // {
  //   id: 17,
  //   image: partner17,
  // },
  // {
  //   id: 18,
  //   image: partner18,
  // },
  // {
  //   id: 19,
  //   image: partner19,
  // },
];
function MediaPartner() {
  return (
    <div className={cx('Component')}>
      <div className={cx('PartnerWrapper')}>
        <div className={cx('CoHost')}>
          <div className={cx('CoHostTitle')}>Organizer</div>
          <div className={cx('LogoList')}>
            <div className={cx('Center')}>
              <img src={isolation} alt="co host" className={cx('imageCo')} />
            </div>
          </div>
        </div>
        <div className={cx('CoHost')}>
          <div className={cx('CoHostTitle')}>Co-Host</div>
          <div className={cx('LogoList')}>
            <div className={cx('CenterHost')}>
              <img src={cohost} alt="co host" className={cx('imageCohost')} />
            </div>
          </div>
        </div>
        <div className={cx('CoHost')}>
          <div className={cx('CoHostTitle')}>Supported by</div>
          <div className={cx('LogoList')}>
            <div className={cx('CenterHost')}>
              <img src={cohost2} alt="co host" className={cx('imageCohost2')} />
            </div>
          </div>
        </div>
      </div>

      <div className={cx('Sponsors')} id="partnership">
        {/* <img className={cx('Background')} src={bg} alt="logo" /> */}

        <div className={cx('Text')}>
          {/* <div className={cx('SubTitle')}>Our Sponsors</div> */}
          <div className={cx('Title')} style={{ minWidth: 326 }}>
            Diamond Sponsor
          </div>
        </div>
        <div className={cx('LogoListSponsor')}>
          <img
            src={logoDiamond1}
            alt="partner logo"
            className={cx('ImageDiamond1')}
          />
          <div className={cx('Line')} />
          <img
            src={logoDiamond2}
            alt="partner logo"
            className={cx('ImageDiamond2')}
          />
          <div className={cx('Line')} />
          <img
            src={logoDiamond3}
            alt="partner logo"
            className={cx('ImageDiamond3')}
          />
        </div>
        {/* <img
          src={logoDiamon}
          alt="partner logo"
          className={cx('ImageDiamond')}
        /> */}

        {/* <div className={cx('Text')}>
          <div className={cx('Title', 'Fz-28')}>Gold Sponsor</div>

          <img src={amazon} alt="partner logo" className={cx('ImageGold')} />
        </div> */}

        {/* <div className={cx('Text', 'MaxWidth', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>Silver Sponsors</div>

          <div className={cx('LogoListDesktop')}>
            <Swiper
              className={cx('Swiper')}
              spaceBetween={32}
              slidesPerView={4}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 4,
                },
              }}
            >
              {SLIVER.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={cx('Center')}>
                    <img
                      src={item.image}
                      alt="co host"
                      className={cx('{item.classNames}')}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {SLIVER.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImageSilver')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div> */}

        <div className={cx('Text', 'MaxWidth', 'MB-0', 'Bronze')}>
          <div className={cx('Title', 'Fz-25')}>Bronze Sponsor</div>
          <div className={cx('CenterTrans')}>
            <img src={phongvu} alt="co host" className={cx('imagePhongVu')} />
          </div>
          {/* <div className={cx('LogoListDesktop')}>
            <Swiper
              className={cx('Swiper')}
              spaceBetween={32}
              slidesPerView={4}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 4,
                },
              }}
            >
              {Bronze.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={cx('Center')}>
                    <img
                      src={item.image}
                      alt="co host"
                      className={cx('ImageBronze')}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {Bronze.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImageSilver')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
        </div>

        {/* <div className={cx('Text', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>Venue Sponsor</div>

          <img src={venua} alt="partner logo" className={cx('ImageVenue')} />
        </div> */}
        {/* <div className={cx('Text', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>Exhibitors</div>

          <div className={cx('grid-container-ex')}>
            <div className={cx('grid-row')}>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex1} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex2} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex3} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex4} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex5} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex6} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
            </div>
            <div className={cx('grid-row')}>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex7} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex8} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
              <div className={cx('PartnerWrapperDesktopItem')}>
                <img src={ex9} alt="exhibitor logo" className={cx('ImageEx')} />
              </div>
            </div>
          </div>

          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {Exhibitor.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImageEx')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className={cx('Btn')}>
            <a href="/exhibitor" className={cx('BtnItem')}>
              See more
            </a>
          </div>
        </div> */}
        <div className={cx('Text', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>
            Private Networking Sponsors
          </div>

          <div className={cx('Private')}>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img src={private1} alt="partner logo" />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img src={private2} alt="partner logo" />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={private3}
                alt="partner logo"
                className={cx('privateImage2')}
              />
            </div>
          </div>
        </div>
        <div className={cx('Text', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>Prize Sponsors</div>

          <div className={cx('ExhibitorWrapperDesktop')}>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={prize1}
                alt="partner logo"
                className={cx('ImagePri1')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={prize2}
                alt="partner logo"
                className={cx('ImagePri2')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={prize3}
                alt="partner logo"
                className={cx('ImagePri3')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={prize4}
                alt="partner logo"
                className={cx('ImagePri4')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={prize5}
                alt="partner logo"
                className={cx('ImagePri5')}
              />
            </div>
          </div>

          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {Prize.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImageExhibitor')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className={cx('Text', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>Exhibitors</div>

          <div className={cx('ExhibitorWrapperDesktop')}>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor6}
                alt="partner logo"
                className={cx('ImageEx6')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor4}
                alt="partner logo"
                className={cx('ImageEx4')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor7}
                alt="partner logo"
                className={cx('ImageEx7')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor8}
                alt="partner logo"
                className={cx('ImageEx8')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor2}
                alt="partner logo"
                className={cx('ImageEx2')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor9}
                alt="partner logo"
                className={cx('ImageEx9')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor3}
                alt="partner logo"
                className={cx('ImageEx3')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor1}
                alt="partner logo"
                className={cx('ImageEx1')}
              />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img
                src={exhibitor5}
                alt="partner logo"
                className={cx('ImageEx5')}
              />
            </div>
          </div>

          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {Exhibitor.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImageExhibitor')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className={cx('LogoBox')}>
          <div className={cx('Text', 'MB-0')}>
            <div className={cx('Title', 'Fz-25')}>Strategic Partner</div>
            <div className={cx('CenterTrans')}>
              <img src={stra} alt="co host" className={cx('imageStra')} />
            </div>
          </div>
          <div className={cx('Text2')}>
            <div className={cx('Title', 'Fz-25')}>
              Official Transportation Partner
            </div>
            <div className={cx('CenterTrans')}>
              <img src={trans} alt="co host" className={cx('imageTrans')} />
            </div>
          </div>
        </div>

        <div className={cx('Text', 'MB-0')}>
          <div className={cx('Title', 'Fz-25')}>Media Partners</div>

          <div className={cx('PartnerWrapperDesktop')}>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img src={media5} alt="partner logo" className={cx('ImageMe5')} />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img src={media6} alt="partner logo" className={cx('ImageMe')} />
            </div>
            <div className={cx('PartnerWrapperDesktopItem')}>
              <img src={media7} alt="partner logo" className={cx('ImageMe7')} />
            </div>
          </div>

          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {Media.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImageMedia')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className={cx('Community')}>
          <div className={cx('Content')}>
            <div className={cx('Title', 'Fz-25')}>Community Partners</div>
          </div>
          <div className={cx('WrapperDesktop')}>
            <Swiper
              navigation={{
                nextEl: '#backComunity',
                prevEl: '#nextComunity',
                // disabledClass: 'disable',
              }}
              modules={[Navigation]}
              className={cx('Swiper')}
              slidesPerView={1}
              slidesPerGroup={1}
            >
              <SwiperSlide className={cx('slide')}>
                <div className={cx('Wrapper')}>
                  <div className={cx('grid-container-co-1')}>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner20}
                        alt="partner logo"
                        className={cx('ImagePartner20')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner21}
                        alt="partner logo"
                        className={cx('ImagePartner21')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner22}
                        alt="partner logo"
                        className={cx('ImagePartner22')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner23}
                        alt="partner logo"
                        className={cx('ImagePartner23')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner24}
                        alt="partner logo"
                        className={cx('ImagePartner24')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner25}
                        alt="partner logo"
                        className={cx('ImagePartner25')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner26}
                        alt="partner logo"
                        className={cx('ImagePartner26')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner27}
                        alt="partner logo"
                        className={cx('ImagePartner27')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner28}
                        alt="partner logo"
                        className={cx('ImagePartner28')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner29}
                        alt="partner logo"
                        className={cx('ImagePartner29')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner30}
                        alt="partner logo"
                        className={cx('ImagePartner30')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner31}
                        alt="partner logo"
                        className={cx('ImagePartner31')}
                      />
                    </div>
                    <div className={cx('PartnerWrapperDesktopItem')}>
                      <img
                        src={partner32}
                        alt="partner logo"
                        className={cx('ImagePartner31')}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className={cx('slide')}>
                <div className={cx('grid-container-co')}>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner34}
                      alt="partner logo"
                      className={cx('ImagePartner34')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner35}
                      alt="partner logo"
                      className={cx('ImagePartner35')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner36}
                      alt="partner logo"
                      className={cx('ImagePartner36')}
                    />
                  </div>
                  {/* <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner37}
                      alt="partner logo"
                      className={cx('ImagePartner37')}
                    />
                  </div> */}
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner38}
                      alt="partner logo"
                      className={cx('ImagePartner38')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner39}
                      alt="partner logo"
                      className={cx('ImagePartner39')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner40}
                      alt="partner logo"
                      className={cx('ImagePartner40')}
                    />
                  </div>
                  {/* <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner41}
                      alt="partner logo"
                      className={cx('ImagePartner41')}
                    />
                  </div> */}
                  {/* <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner42}
                      alt="partner logo"
                      className={cx('ImagePartner42')}
                    />
                  </div> */}
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner43}
                      alt="partner logo"
                      className={cx('ImagePartner43')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner44}
                      alt="partner logo"
                      className={cx('ImagePartner44')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner45}
                      alt="partner logo"
                      className={cx('ImagePartner45')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner42}
                      alt="partner logo"
                      className={cx('ImagePartner42')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner47}
                      alt="partner logo"
                      className={cx('ImagePartner47')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner72}
                      alt="partner logo"
                      className={cx('ImagePartner72')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner73}
                      alt="partner logo"
                      className={cx('ImagePartner73')}
                    />
                  </div>
                  {/* <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner48}
                      alt="partner logo"
                      className={cx('ImagePartner48')}
                    />
                  </div> */}
                  {/* <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner49}
                      alt="partner logo"
                      className={cx('ImagePartner49')}
                    />
                  </div> */}
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner64}
                      alt="partner logo"
                      className={cx('ImagePartner64')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner65}
                      alt="partner logo"
                      className={cx('ImagePartner65')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner66}
                      alt="partner logo"
                      className={cx('ImagePartner66')}
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className={cx('slide')}>
                <div className={cx('grid-container-co-2')}>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner50}
                      alt="partner logo"
                      className={cx('ImagePartner50')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner51}
                      alt="partner logo"
                      className={cx('ImagePartner51')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner52}
                      alt="partner logo"
                      className={cx('ImagePartner52')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner53}
                      alt="partner logo"
                      className={cx('ImagePartner53')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner54}
                      alt="partner logo"
                      className={cx('ImagePartner54')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner55}
                      alt="partner logo"
                      className={cx('ImagePartner55')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner56}
                      alt="partner logo"
                      className={cx('ImagePartner56')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner57}
                      alt="partner logo"
                      className={cx('ImagePartner57')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner33}
                      alt="partner logo"
                      className={cx('ImagePartner33')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner58}
                      alt="partner logo"
                      className={cx('ImagePartner58')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner59}
                      alt="partner logo"
                      className={cx('ImagePartner59')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner60}
                      alt="partner logo"
                      className={cx('ImagePartner60')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner61}
                      alt="partner logo"
                      className={cx('ImagePartner61')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner62}
                      alt="partner logo"
                      className={cx('ImagePartner62')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner63}
                      alt="partner logo"
                      className={cx('ImagePartner63')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner67}
                      alt="partner logo"
                      className={cx('ImagePartner67')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner68}
                      alt="partner logo"
                      className={cx('ImagePartner68')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner70}
                      alt="partner logo"
                      className={cx('ImagePartner70')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner71}
                      alt="partner logo"
                      className={cx('ImagePartner71')}
                    />
                  </div>
                  <div className={cx('PartnerWrapperDesktopItem')}>
                    <img
                      src={partner69}
                      alt="partner logo"
                      className={cx('ImagePartner69')}
                    />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <img
              src={back}
              alt="back"
              className={cx('Back')}
              id="backComunity"
            />
            <img
              src={next}
              alt="next"
              className={cx('Next')}
              id="nextComunity"
            />
          </div>
          <div className={cx('LogoListMobile')}>
            <Swiper
              className={cx('Swiper')}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
            >
              {Partner.map((item) => (
                <SwiperSlide key={item.id} className={cx('slide')}>
                  <div className={cx('Center')}>
                    <div className={cx('card')}>
                      <img
                        src={item.image}
                        alt="co host"
                        className={cx('ImagePartner')}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaPartner;
