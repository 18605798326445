import React from 'react';
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';
import Header from './Header';
import Footer from './Footer';

const cx = classNames.bind(styles);

function Layout() {
  return (
    <div className={cx('App')}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

Layout.propTypes = {

};

export default Layout;
