import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import classNames from 'classnames/bind';
// import vector from 'assets/images/event/Vector (13).png';
// import byteplus from 'assets/images/sponsor/Container.png';
// import aws from 'assets/images/sponsor/Vector (16).png';
// import vngcloud1 from 'assets/images/sponsor/Group (1).png';
// import dewards1 from 'assets/images/sponsor/Group (2).png';
// import vngcloud2 from 'assets/images/sponsor/Group (3).png';
import dewards2 from 'assets/images/sponsor/Group (11).png';
// import mlogo from 'assets/images/sponsor/Frame 80.png';
import bg1 from 'assets/images/sponsor/Background Artwork (1).png';
import bg2 from 'assets/images/sponsor/Background Artwork (2).png';
import intel from 'assets/images/sponsor/Frame 785.png';
import ctrl from 'assets/images/sponsor/Group (10).png';
import cloud from 'assets/images/sponsor/Group (9).png';
import pv from 'assets/images/sponsor/Layer_1 (14).png';
import blance from 'assets/images/sponsor/Layer_1 (15).png';
import ede from 'assets/images/sponsor/Bronze Sponsors.png';
import pri1 from 'assets/images/sponsor/pri1.png';
import pri2 from 'assets/images/sponsor/pri2.png';
import pri3 from 'assets/images/sponsor/pri3.png';
import pri4 from 'assets/images/sponsor/pri4.png';
import pri5 from 'assets/images/sponsor/pri5.png';

import styles from './Sponsor.module.scss';

const cx = classNames.bind(styles);
// const SILVER_LIST = [
//   {
//     id: '1',
//     image: vngcloud1,
//     name: 'VNGCloud',
//   },
//   {
//     id: '2',
//     image: dewards1,
//     name: 'Dewar’s',
//   },
// ];
const BRONZE_LIST = [
  {
    id: '1',
    image: blance,
    name: 'Blanc de blancs',
    link: 'https://www.blanc-de-blancs.co/',
  },
  {
    id: '2',
    image: dewards2,
    name: 'Dewar’s',
    link: 'https://www.dewars.com/',
  },
  {
    id: '3',
    image: ede,
    name: 'Edelweiss',
    link: 'https://www.edelweissbeer.com/global/en/agegateway?returnurl=%2f',
  },
];
const PRIZE_LIST = [
  {
    id: '1',
    image: pri1,
    name: 'Gohub',
    link: 'https://gohub.vn/',
  },
  {
    id: '2',
    image: pri2,
    name: 'Lamy',
    link: 'https://lamy.vn/collections/but-may',
  },
  {
    id: '3',
    image: pri3,
    name: 'Hoiana',
    link: 'https://hoiana.com/',
  },
  {
    id: '4',
    image: pri4,
    name: 'Dai Phat',
    link: 'https://daiphatfood.com.vn/en/',
  },
  {
    id: '5',
    image: pri5,
    name: 'Tupperware',
    link: 'https://www.tupperware.com.vn/vi/index',
  },
];
function Sponsor() {
  return (
    <div className={cx('Component')}>
      <img src={bg1} alt="" className={cx('BG1')} />
      <img src={bg2} alt="" className={cx('BG2')} />

      <div className={cx('Content')}>
        <div className={cx('Text')}>OUR SPONSORS</div>
      </div>
      <div className={cx('Wrapper')}>
        <div className={cx('Contain')}>
          {/* <div className={cx('Box', 'Update')}>
            <img src={vector} alt="" className={cx('image')} />
          </div> */}
          <div className={cx('WrapperBox1')}>
            <div className={cx('Box')}>
              <div className={cx('Left')}>
                <p className={cx('Title')}>Diamond Sponsor</p>
                <div className={cx('Logo')}>
                  <img src={intel} alt="logo" className={cx('Intel')} />
                  <p className={cx('Name')}>intel</p>
                </div>
                <div className={cx('btn')}>
                  <a
                    href="https://www.intel.com/content/www/us/en/homepage.html"
                    className={cx('Item')}
                    target="blank"
                  >
                    Visit their website
                  </a>
                </div>
              </div>
              <div className={cx('Right')}>
                <p>
                  Intel (Nasdaq: INTC) is an industry leader, creating
                  world-changing technology that enables global progress and
                  enriches lives. Inspired by Moore’s Law, we continuously work
                  to advance the design and manufacturing of semiconductors to
                  help address our customers’ greatest challenges.
                </p>
                <p>
                  By embedding intelligence in the cloud, network, edge, and
                  every kind of computing device, we unleash the potential of
                  data to transform business and society for the better. To
                  learn more about Intel’s innovations, go to newsroom.intel.com
                  and intel.com.
                </p>
              </div>
              <div className={cx('btnMobile')}>
                <a
                  href="https://www.intel.com/content/www/us/en/homepage.html"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>
          </div>

          <div className={cx('WrapperBox2')}>
            <div className={cx('Box')}>
              <div className={cx('Left')}>
                <p className={cx('Title')}>Diamond Sponsor</p>
                <div className={cx('Logo')}>
                  <div className={cx('LogoListDiamond')}>
                    <img src={cloud} alt="logo" className={cx('Cloud')} />
                    <div className={cx('Line')} />
                    <img src={ctrl} alt="logo" className={cx('Ctrl')} />
                  </div>
                  <p className={cx('Name')}>CtrlS-Cloud4C </p>
                </div>
                <div className={cx('btn')}>
                  <a
                    href="https://www.ctrls.com/"
                    className={cx('Item')}
                    target="blank"
                  >
                    Visit their website
                  </a>
                </div>
              </div>
              <div className={cx('Right')}>
                <p>
                  CtrlS-Cloud4C Group is one of the world’s largest Rated 4
                  Datacenter companies and a globally leading automation-driven,
                  application-focused Cloud Managed Services Provider serving
                  4000+ enterprises including 60 of Fortune 500 companies across
                  30+ countries. Equipped with 25 Centers of Excellence, 2000+
                  certified cloud experts, CtrlS-Cloud4C group positions itself
                  as an end-to-end digital transformation partner for delivering
                  state-of-the-art in-country compliant co-location, cloud
                  hosting, cloud migration, and managed services for
                  private-public-hybrid-multi-cloud environments in a single SLA
                  from infra till application login.
                </p>
                <p>
                  Since 2007, CtrlS-Cloud4C has been committed to innovation and
                  excellence with an extended portfolio that includes xPU Cloud,
                  AI-as-a-Service, GenAI consulting and solutions, Deep
                  Analytics, and more. As a trailblazer in innovation, CtrlS has
                  accumulated an impressive portfolio of 200 innovations, with
                  an emphasis on 80 innovations dedicated to energy efficiency.
                </p>
                <p>
                  CtrlS plans to invest $2 billion in AI-ready Datacenter,
                  Cloud, and Advanced Technologies and achieve net zero by 2030.
                </p>
              </div>
              <div className={cx('btnMobile')}>
                <a
                  href="https://www.ctrls.com/"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>
          </div>

          <div className={cx('WrapperBoxDesktop1')}>
            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Bronze Sponsor</p>
              <div className={cx('Logo')}>
                <img src={pv} alt="logo" className={cx('pv')} />
                <p className={cx('Name')}>
                  PHONG VU TRADING & SERVICE CORPORATION
                </p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://phongvu.vn/"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>
          </div>
          {/* <div className={cx('WrapperMobile')}>
            <Swiper
              modules={[Pagination]}
              className={cx('Swiper')}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
            >
              {SILVER_LIST.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={cx('Contain')}>
                    <div className={cx('Card')}>
                      <div className={cx('CardWrapper')}>
                        <p className={cx('Title')}>Silver Sponsor</p>
                        <div className={cx('Logo')}>
                          <img
                            src={item.image}
                            alt="logo"
                            className={cx('vngcloud1')}
                          />
                          <p className={cx('Name')}>{item.name}</p>
                        </div>
                        <div className={cx('btn')}>
                          <a href="/" className={cx('Item')} target="blank">
                            Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
          <div className={cx('WrapperBoxDesktop2')}>
            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Private Networking Sponsor</p>
              <div className={cx('Logo')}>
                <img src={blance} alt="logo" className={cx('blance')} />
                <p className={cx('Name')}>Blanc de blancs</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://www.blanc-de-blancs.co/"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>

            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Private Networking Sponsor</p>
              <div className={cx('Logo')}>
                <img src={dewards2} alt="logo" className={cx('dewards2')} />
                <p className={cx('Name')}>Dewar’s</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://www.dewars.com//"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>

            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Private Networking Sponsor</p>
              <div className={cx('Logo')}>
                <img src={ede} alt="logo" className={cx('')} />
                <p className={cx('Name')}>Edelweiss</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://www.edelweissbeer.com/global/en/agegateway?returnurl=%2f"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>
          </div>
          <div className={cx('WrapperMobileBronze')}>
            <Swiper
              modules={[Pagination]}
              className={cx('Swiper')}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
            >
              {BRONZE_LIST.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={cx('Contain')}>
                    <div className={cx('Card')}>
                      <div className={cx('CardWrapper')}>
                        <p className={cx('Title')}>
                          Private Networking Sponsor
                        </p>
                        <div className={cx('Logo')}>
                          <img
                            src={item.image}
                            alt="logo"
                            className={cx('vngcloud1')}
                          />
                          <p className={cx('Name')}>{item.name}</p>
                        </div>
                        <div className={cx('btn')}>
                          <a
                            href={item.link}
                            className={cx('Item')}
                            target="blank"
                          >
                            Visit their website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className={cx('WrapperBoxDesktop2')}>
            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Prize Sponsor</p>
              <div className={cx('Logo')}>
                <img src={pri1} alt="logo" className={cx('pri')} />
                <p className={cx('Name')}>Gohub</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://gohub.vn/"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>

            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Prize Sponsor</p>
              <div className={cx('Logo')}>
                <img src={pri2} alt="logo" className={cx('pri')} />
                <p className={cx('Name')}>LAMY</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://lamy.vn/collections/but-may"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>

            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Prize Sponsor</p>
              <div className={cx('Logo')}>
                <img src={pri3} alt="logo" className={cx('pri')} />
                <p className={cx('Name')}>Hoiana</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://hoiana.com/"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>
          </div>
          <div className={cx('WrapperBoxDesktop2')}>
            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Prize Sponsor</p>
              <div className={cx('Logo')}>
                <img src={pri4} alt="logo" className={cx('pri')} />
                <p className={cx('Name')}>Dai Phat</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://daiphatfood.com.vn/en/"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>

            <div className={cx('ContainBox')}>
              <p className={cx('Title')}>Prize Sponsor</p>
              <div className={cx('Logo')}>
                <img src={pri5} alt="logo" className={cx('pri')} />
                <p className={cx('Name')}>Tupperware</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href="https://www.tupperware.com.vn/vi/index"
                  className={cx('Item')}
                  target="blank"
                >
                  Visit their website
                </a>
              </div>
            </div>
          </div>
          <div className={cx('WrapperMobileBronze')}>
            <Swiper
              modules={[Pagination]}
              className={cx('Swiper')}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
            >
              {PRIZE_LIST.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={cx('Contain')}>
                    <div className={cx('Card')}>
                      <div className={cx('CardWrapper')}>
                        <p className={cx('Title')}>Prize Sponsor</p>
                        <div className={cx('Logo')}>
                          <img
                            src={item.image}
                            alt="logo"
                            className={cx('vngcloud1')}
                          />
                          <p className={cx('Name')}>{item.name}</p>
                        </div>
                        <div className={cx('btn')}>
                          <a
                            href={item.link}
                            className={cx('Item')}
                            target="blank"
                          >
                            Visit their website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sponsor;
