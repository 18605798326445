import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import bgTopic1 from 'assets/images/topic/bg-topic-1.png';
import bgTopic2 from 'assets/images/topic/bg-topic-2.png';
import bgTopic3 from 'assets/images/topic/bg-topic-3.png';
import bgTopic4 from 'assets/images/topic/bg-topic-4.png';
import bgTopic5 from 'assets/images/topic/bg-topic-5.png';
import topic1 from 'assets/images/topic/topic-1.png';
import topic2 from 'assets/images/topic/topic-2.png';
import topic3 from 'assets/images/topic/topic-3.png';
import topic4 from 'assets/images/topic/topic-4.png';
import topic5 from 'assets/images/topic/topic-5.png';

// import bg from 'assets/images/topic/Background Glow.png';
import glow1 from 'assets/images/topic/Background Glow (1).png';
import glow2 from 'assets/images/topic/Background Glow (2).png';
// import workshop1 from 'assets/images/topic/Mask group (1).png';
// import workshop2 from 'assets/images/topic/Frame 450.png';
// import workshop3 from 'assets/images/topic/Frame 450 (1).png';

import bu1 from 'assets/images/topic/Rectangle 30 (1).png';
import bu2 from 'assets/images/topic/Rectangle 30 (2).png';
import bu3 from 'assets/images/topic/Rectangle 30 (3).png';

import fi1 from 'assets/images/topic/Mask group (2).png';
import fi2 from 'assets/images/topic/Frame 512.png';
import fi3 from 'assets/images/topic/Mask group (6).png';

import pre1 from 'assets/images/topic/pre1.png';
import pre2 from 'assets/images/topic/pre2.png';
import pre3 from 'assets/images/topic/pre3.png';
import pre4 from 'assets/images/topic/pre4.png';

import back from 'assets/images/speaker/back.svg';
import next from 'assets/images/speaker/next.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './Topic.module.scss';

const cx = classNames.bind(styles);

const TOPIC_LIST = [
  {
    id: '1',
    image: topic1,
    background: bgTopic1,
    title: 'The Future of AI in Healthcare',
    link: '##',
    classname: 'Topic1',
  },
  {
    id: '2',
    image: topic5,
    background: bgTopic5,
    title: 'The Future of AI in BFSI',
    link: '##',
    classname: 'Topic2',
  },
  {
    id: '3',
    image: topic2,
    background: bgTopic2,
    title: 'The Future of AI in Retail',
    link: '##',
    classname: 'Topic3',
  },
  {
    id: '4',
    image: topic3,
    background: bgTopic3,
    title: 'The Future of AI in Education',
    link: '##',
    classname: 'Topic4',
  },
  {
    id: '5',
    image: topic4,
    background: bgTopic4,
    title: 'The Future of AI in Manufacturing',
    link: '##',
    classname: 'Topic5',
  },
];

// const WORKSHOP_LIST = [
//   {
//     id: '1',
//     image: workshop1,
//     background: bg,
//     title: 'How To Deploy AI Apps On The Cloud',
//     link: '##',
//     classname: 'workshop1',
//   },
//   {
//     id: '2',
//     image: workshop2,
//     background: bg,
//     title: 'How To Run LLMs On Your Personal Device, Offline',
//     link: '##',
//     classname: 'workshop2',
//   },
//   {
//     id: '3',
//     image: workshop3,
//     background: bg,
//     title: 'How To Attract, Hire, And Retain AI Tech Talent',
//     link: '##',
//     classname: 'workshop3',
//   },
// ];

const BUSINESS_LIST = [
  {
    id: '1',
    image: bu1,
    title: 'Launch Zone',
    desc: 'Catalysing digital transformation in the form B2B business matching meetings, diligently curated to match corporate problem statements with relevant AI technologies and solutions.',
    link: '##',
    classname: 'workshop1',
  },
  {
    id: '2',
    image: bu2,
    title: 'Talent Zone',
    desc: "Tap into Vietnam's dynamic pool of skilled tech talents in a focused and efficient manner. Discover, evaluate, and recruit these AI professionals that are the best fit to propel your technology development goals to the next level.",
    link: '##',
    classname: 'workshop2',
  },
  {
    id: '3',
    image: bu3,
    title: 'Capital Zone',
    desc: 'Dive into curated funding opportunities/deal flow through Investor-Startup speed dating sessions, connecting AI startups from Singapore and Vietnam with diverse investors, from angels to VCs, early to growth stage.',
    link: '##',
    classname: 'workshop3',
  },
];

const FIRESIDE_LIST = [
  {
    id: '1',
    image: fi1,
    title: 'From Sci-Fi To Reality',
    desc: 'Bringing Jarvis, Joi, And John-117 To Life',
    link: '##',
    classname: 'fi1',
  },
  {
    id: '2',
    image: fi2,
    title: 'AI for Good',
    desc: 'Leveraging Technology for Sustainable Impact',
    link: '##',
    classname: 'fi2',
  },
  {
    id: '3',
    image: fi3,
    title: 'Game Changers',
    desc: 'Revolutionizing sports and entertainment with AI',
    link: '##',
    classname: 'fi3',
  },
];

const PRESENTATION = [
  {
    id: '1',
    image: pre1,
    title: 'Generative AI: Catalyzing The Journey Towards Industry Adoption',
    link: '##',
    classname: 'Topic1',
  },
  {
    id: '2',
    image: pre2,
    title: 'AGI: Hype, Hope, Or Hallucination?',
    link: '##',
    classname: 'Topic2',
  },
  {
    id: '3',
    image: pre3,
    title: 'Ethical AI: Prepare For A New Future',
    link: '##',
    classname: 'Topic3',
  },
  {
    id: '4',
    image: pre4,
    title: 'GANs: Demystifying Generative AI Models',
    link: '##',
    classname: 'Topic4',
  },
];
function Topic() {
  return (
    <div className={cx('Component')} id="topics">
      <div className={cx('Panel')}>
        <div className={cx('Content')}>
          <div className={cx('Text')}>Conference</div>
          <div className={cx('Title')}>Panel Discussion</div>
        </div>

        <div className={cx('WrapperDesktop')}>
          <Swiper
            navigation={{
              nextEl: '#backCo',
              prevEl: '#nextCo',
              disabledClass: 'disable',
            }}
            modules={[Navigation]}
            className={cx('Swiper')}
            spaceBetween={22}
            slidesPerView={3}
            slidesPerGroup={3}
          >
            {TOPIC_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Card')}>
                  <div className={cx('CardWrapper')}>
                    <img
                      className={cx('Bg')}
                      src={item.background}
                      alt="Background"
                    />
                    <img
                      className={cx('Item', item.id === '5' && 'LastItem')}
                      src={item.image}
                      alt="Item"
                    />
                    <div className={cx('CardContent')}>
                      <div className={cx('Title')}>{item.title}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <img src={back} alt="back" className={cx('Back')} id="backCo" />
          <img src={next} alt="next" className={cx('Next')} id="nextCo" />
        </div>

        <div className={cx('WrapperMobile')}>
          <Swiper
            modules={[Pagination]}
            className={cx('Swiper')}
            spaceBetween={32}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
          >
            {TOPIC_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Contain')}>
                  <div className={cx('Card')}>
                    <div className={cx('CardWrapper')}>
                      <img
                        className={cx('Bg')}
                        src={item.background}
                        alt="Background"
                      />
                      <img
                        className={cx('Item', item.id === '5' && 'LastItem')}
                        src={item.image}
                        alt="Item"
                      />
                      <div className={cx('CardContent')}>
                        <div className={cx('Title')}>{item.title}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className={cx('Fireside')}>
        <div className={cx('Content')}>
          {/* <div className={cx('Text')}>Fireside Chat</div> */}
          <div className={cx('Title')}>Fireside Chat</div>
        </div>

        <div className={cx('WrapperDesktop')}>
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: '#backFi',
              prevEl: '#nextFi',
              disabledClass: 'disable',
            }}
            className={cx('Swiper')}
            spaceBetween={32}
            slidesPerView={2}
          >
            {FIRESIDE_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Card')}>
                  <div className={cx('CardWrapper')}>
                    <img
                      className={cx('Item', item.id === '3' && 'LastItem')}
                      src={item.image}
                      alt="Item"
                    />
                    <img src={glow2} alt="" className={cx('glow2')} />

                    <div className={cx('CardContent')}>
                      <img src={glow1} alt="" className={cx('glow1')} />
                      <div className={cx('Title')}>{item.title}</div>
                      <div className={cx('Desc')}>{item.desc}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <img src={back} alt="back" className={cx('Back')} id="backFi" />
          <img src={next} alt="next" className={cx('Next')} id="nextFi" />
        </div>
        <div className={cx('WrapperMobile')}>
          <Swiper
            modules={[Pagination]}
            className={cx('Swiper')}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
          >
            {FIRESIDE_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Contain')}>
                  <div className={cx('Card')}>
                    <div className={cx('CardWrapper')}>
                      <img
                        className={cx('Item', item.id === '3' && 'LastItem')}
                        src={item.image}
                        alt="Item"
                      />
                      <div className={cx('CardContent')}>
                        <img src={glow1} alt="" className={cx('glow1')} />
                        <img src={glow2} alt="" className={cx('glow2')} />
                        <div className={cx('Title')}>{item.title}</div>
                        <div className={cx('Desc')}>{item.desc}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className={cx('Presentation')}>
        <div className={cx('Content')}>
          {/* <div className={cx('Text')}>Conference</div> */}
          <div className={cx('Title')}>Presentation</div>
        </div>

        <div className={cx('WrapperDesktop')}>
          <Swiper
            navigation={{
              nextEl: '#backPre',
              prevEl: '#nextPre',
              disabledClass: 'disable',
            }}
            modules={[Navigation]}
            className={cx('Swiper')}
            spaceBetween={24}
            slidesPerView={2}
            slidesPerGroup={2}
          >
            {PRESENTATION.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Card')}>
                  <div className={cx('CardWrapper')}>
                    <img
                      className={cx('Item', item.id === '5' && 'LastItem')}
                      src={item.image}
                      alt="Item"
                    />
                    <div className={cx('CardContent')}>
                      <img src={glow1} alt="" className={cx('glow1')} />
                      <div className={cx('Title')}>{item.title}</div>
                    </div>
                    <img src={glow2} alt="" className={cx('glow2')} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <img src={back} alt="back" className={cx('Back')} id="backPre" />
          <img src={next} alt="next" className={cx('Next')} id="nextPre" />
        </div>

        <div className={cx('WrapperMobile')}>
          <Swiper
            modules={[Pagination]}
            className={cx('Swiper')}
            spaceBetween={32}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
          >
            {PRESENTATION.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Contain')}>
                  <div className={cx('Card')}>
                    <div className={cx('CardWrapper')}>
                      <img
                        className={cx('Item', item.id === '5' && 'LastItem')}
                        src={item.image}
                        alt="Item"
                      />
                      <div className={cx('CardContent')}>
                        <img src={glow1} alt="" className={cx('glow1')} />
                        <img src={glow2} alt="" className={cx('glow2')} />
                        <div className={cx('Title')}>{item.title}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* <div className={cx('Workshop')}>
        <div className={cx('Content')}>
          <div className={cx('Text')}>WORKSHOP</div>
          <div className={cx('Title')}>AI Tools & Technologies Deep Dive</div>
        </div>

        <div className={cx('WrapperDesktop')}>
          <Swiper
            modules={[Autoplay, Navigation]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className={cx('Swiper')}
            spaceBetween={32}
            slidesPerView={3}
          >
            {WORKSHOP_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Card')}>
                  <div className={cx('CardWrapper')}>
                    <img
                      className={cx('Bg')}
                      src={item.background}
                      alt="Background"
                    />
                    <img
                      className={cx('Item', item.id === '5' && 'LastItem')}
                      src={item.image}
                      alt="Item"
                    />
                    <div className={cx('CardContent')}>
                      <div className={cx('Title')}>{item.title}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className={cx('WrapperMobile')}>
          <Swiper
            modules={[Pagination]}
            className={cx('Swiper')}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
          >
            {WORKSHOP_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Contain')}>
                  <div className={cx('Card')}>
                    <div className={cx('CardWrapper')}>
                      <img
                        className={cx('Bg')}
                        src={item.background}
                        alt="Background"
                      />
                      <img
                        className={cx('Item', item.id === '5' && 'LastItem')}
                        src={item.image}
                        alt="Item"
                      />
                      <div className={cx('CardContent')}>
                        <div className={cx('Title')}>{item.title}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div> */}

      <div className={cx('Business')}>
        <div className={cx('Content')}>
          <div className={cx('Text')}>Business Lounge</div>
          <div className={cx('Title')}>Tangible Outcomes & ROI</div>
        </div>

        <div className={cx('WrapperDesktop')}>
          <Swiper
            modules={[Navigation]}
            className={cx('Swiper')}
            spaceBetween={32}
            slidesPerView={3}
          >
            {BUSINESS_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Card')}>
                  <div className={cx('CardWrapper')}>
                    <img
                      className={cx('Item', item.id === '5' && 'LastItem')}
                      src={item.image}
                      alt="Item"
                    />
                    <div className={cx('CardContent')}>
                      <div className={cx('Title')}>{item.title}</div>
                      <div className={cx('Text')}>{item.desc}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={cx('WrapperMobile')}>
          <Swiper
            modules={[Pagination]}
            className={cx('Swiper')}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
          >
            {BUSINESS_LIST.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={cx('Contain')}>
                  <div className={cx('Card')}>
                    <div className={cx('CardWrapper')}>
                      <img
                        className={cx('Item', item.id === '5' && 'LastItem')}
                        src={item.image}
                        alt="Item"
                      />
                      <div className={cx('CardContent')}>
                        <div className={cx('Title')}>{item.title}</div>
                        <div className={cx('Text')}>{item.desc}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Topic;
