import React from 'react';
import classNames from 'classnames/bind';
import Countdown from 'react-countdown';

import facebook from 'assets/images/footer/facebook.svg';
import linkedin from 'assets/images/footer/linkedin.svg';
import logo from 'assets/images/footer/logo-footer.svg';

import { LOCATION } from 'constants/index';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);
const dateCountDown = new Date(2024, 6, 12, 8, 30, 0, 0);

function Footer() {
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
  }) => (
    <>
      <div className={cx('Day')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{days}</span>
        <p>DAY</p>
      </div>
      <div className={cx('Hour')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{hours}</span>
        <p>HOUR</p>
      </div>
      <div className={cx('Minute')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{minutes}</span>
        <p>MINUTE</p>
      </div>
      <div className={cx('SECOND')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{seconds}</span>
        <p>SECOND</p>
      </div>
    </>
  );
  return (
    <div className={cx('Component')} id="contact">
      <div className={cx('WrapperCountDow')}>
        <div className={cx('CountDown')}>
          <Countdown date={dateCountDown} renderer={renderer} />
        </div>

        <div className={cx('Btn')}>
          <a
            href="https://lu.ma/TheFutureofAIChapter2"
            target="_blank"
            className={cx('BtnItem')}
            rel="noreferrer"
          >
            Register Now
          </a>
        </div>
      </div>
      <div className={cx('Content')}>
        <div className={cx('Left')}>
          <div className={cx('Wrapper')}>
            <div className={cx('Title')}>Brought to you by</div>

            <img className={cx('Logo')} src={logo} alt="logo" />
          </div>

          <div className={cx('Text')}>
            <p>
              Launch JDI is the digital transformation arm of JDI, empowering
              companies to accelerate growth into Vietnam quickly and
              profitably.
            </p>
            <p>
              From market entry, business setup to establishing a local
              presence, we leverage our local networks, expertise, and resources
              to resolve your market challenges.
            </p>
          </div>
        </div>

        <div className={cx('Right')}>
          <div className={cx('Title')}>For Sponsors, Exhibitors & Partners</div>

          <div className={cx('Location')}>
            {/* <span>Mr. Trung Nguyen</span> */}

            <div className={cx('Info')}>
              <a href="mailto:expansion@jdi.group">expansion@jdi.group</a>
              {/* <a href="tel:+84984598471">+84 984 598 471</a> */}
            </div>
          </div>

          <div className={cx('Title')}>For Tickets & General Information</div>

          <div className={cx('Location')}>
            <div className={cx('Info')}>
              <a href="mailto:marketing@jdi.group">marketing@jdi.group</a>
            </div>
          </div>

          <div className={cx('Social')}>
            <div className={cx('SocialWrapper')}>
              <a
                href={LOCATION.FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className={cx('Icon')} src={facebook} alt="facebook" />
              </a>
              <a
                href={LOCATION.LINKEDIN}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className={cx('Icon')} src={linkedin} alt="linkedin" />
              </a>
            </div>

            <span>© 2023 JDI Group. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
