import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import imgTopic1 from 'assets/images/unveil/Rectangle 21 (1).png';
import imgTopic2 from 'assets/images/unveil/Rectangle 21.png';
// import imgTopic3 from 'assets/images/unveil/Rectangle 21 (2).png';
import imgTopic4 from 'assets/images/unveil/Rectangle 21 (3).png';
import imgTopic5 from 'assets/images/unveil/Rectangle 21 (4).png';
import back from 'assets/images/speaker/back.svg';
import next from 'assets/images/speaker/next.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from './Unveil.module.scss';

const cx = classNames.bind(styles);

const TOPIC_LIST = [
  {
    id: '1',
    image: imgTopic1,
    title: 'Exhibition ',
    desc: 'Discover and experience emerging AI technologies and solutions from Singapore and Vietnam.',
    link: '##',
    classname: 'Topic1',
  },
  {
    id: '2',
    image: imgTopic2,
    title: 'Conference ',
    desc: 'Gain deep insights and practical knowledge from panel discussions, fireside chats, and presentations led by AI experts and esteemed industry leaders.',
    link: '##',
    classname: 'Topic2',
  },
  // {
  //   id: '3',
  //   image: imgTopic3,
  //   title: 'Workshops ',
  //   desc: 'A series of business and technical “how-tos” and “101” around the application of AI',
  //   link: '##',
  //   classname: 'Topic3',
  // },
  {
    id: '4',
    image: imgTopic4,
    title: 'Business Lounge ',
    desc: 'Engage directly with industry experts on how to propel businesses toward unprecedented success through market entry, strategic funding, and talent.',
    link: '##',
    classname: 'Topic4',
  },
  {
    id: '5',
    image: imgTopic5,
    title: 'Networking ',
    desc: 'Connect with +500 industry peers, AI experts, and like-minded professionals to forge new partnerships, share insights, and explore potential synergies.',
    link: '##',
    classname: 'Topic5',
  },
];

function Unveil() {
  return (
    <div className={cx('Component')}>
      <div className={cx('WrapContent')}>
        <div className={cx('TitleContent')}>
          UnVeil The Power Of Artificial Intelligence
        </div>
      </div>

      <div className={cx('WrapperDesktop')}>
        <Swiper
          navigation={{
            nextEl: '#backUn',
            prevEl: '#nextUn',
            disabledClass: 'disable',
          }}
          modules={[Navigation]}
          className={cx('Swiper')}
          spaceBetween={22}
          slidesPerView={3}
          slidesPerGroup={3}
        >
          {TOPIC_LIST.map((item) => (
            <SwiperSlide key={item.id}>
              <div className={cx('Card')}>
                <div className={cx('CardWrapper')}>
                  <img className={cx('Item')} src={item.image} alt="Item" />
                  <div className={cx('CardContent')}>
                    <div className={cx('Title')}>{item.title}</div>
                    <div className={cx('Desc')}>{item.desc}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <img src={back} alt="back" className={cx('Back')} id="backUn" />
        <img src={next} alt="next" className={cx('Next')} id="nextUn" />
      </div>
      <div className={cx('WrapperMobile')}>
        <Swiper
          modules={[Pagination]}
          className={cx('Swiper')}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
        >
          {TOPIC_LIST.map((item) => (
            <SwiperSlide key={item.id}>
              <div className={cx('Contain')}>
                <div className={cx('Card')}>
                  <div className={cx('CardWrapper')}>
                    <img className={cx('Item')} src={item.image} alt="Item" />
                    <div className={cx('CardContent')}>
                      <div className={cx('Title')}>{item.title}</div>
                      <div className={cx('Desc')}>{item.desc}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Unveil;
