import React from 'react';
import classNames from 'classnames/bind';
import vector1 from 'assets/images/secure/Vector (10).png';
import vector2 from 'assets/images/secure/Vector (11).png';
import vector3 from 'assets/images/secure/Vector (12).png';
import note from 'assets/images/secure/Frame.png';
import styles from './Secure.module.scss';

const cx = classNames.bind(styles);
function Secure() {
  return (
    <div className={cx('Component')}>
      <div className={cx('Content')}>
        <p className={cx('Title')}>Secure your ticket</p>
      </div>
      <div className={cx('Wrap')}>
        <div className={cx('Wrapper')}>
          <div className={cx('Box')}>
            <img src={vector1} alt="" className={cx('vector2')} />
            <img src={vector2} alt="" className={cx('vector1')} />
            <img src={vector3} alt="" className={cx('vector3')} />
            <div className={cx('Content')}>
              <div className={cx('Row1')}>
                <div className={cx('ContentBox')}>
                  <p className={cx('Txt')}>General Admission Ticket</p>
                  <div className={cx('WrapText')}>Free</div>
                  <p className={cx('Txt')}>
                    Gain access to The Future of Artificial Intelligence:
                    Chapter 2 for an entire day of activities to immerse in the
                    world of AI.
                  </p>
                </div>
              </div>
              <div className={cx('Row2')}>
                <div className={cx('Contain')}>
                  <img src={note} alt="" className={cx('image')} />
                  <p className={cx('text')}>Event Entrance Pass</p>
                </div>

                <div className={cx('Contain')}>
                  <img src={note} alt="" className={cx('image')} />
                  <p className={cx('text')}>
                    Full Access to AI Exhibition Area
                  </p>
                </div>

                {/* <div className={cx('Contain')}>
                <img src={note} alt="" className={cx('image')} />
                <p className={cx('text')}>Full Access to AI Workshop Hall</p>
              </div> */}

                <div className={cx('Contain')}>
                  <img src={note} alt="" className={cx('image')} />
                  <p className={cx('text')}>
                    Full Access to Business Lounge for Launch Zone, Capital
                    Zone, and Talent Zone
                  </p>
                </div>

                <div className={cx('Contain')}>
                  <img src={note} alt="" className={cx('image')} />
                  <p className={cx('text')}>
                    Full Access to Conference Hall For Presentations, Fireside
                    Chats, and Panel Discussions
                  </p>
                </div>

                <div className={cx('Contain')}>
                  <img src={note} alt="" className={cx('image')} />
                  <p className={cx('text')}>
                    Networking Party with Over 500 like-minded Professionals
                  </p>
                </div>

                <div className={cx('Contain')}>
                  <img src={note} alt="" className={cx('image')} />
                  <p className={cx('text')}>
                    Complimentary Tea-breaks and Beverages
                  </p>
                </div>
              </div>
              <a
                href="https://www.eventbrite.sg/e/the-future-of-artificial-intelligence-chapter-2-tickets-933089775637?aff=oddtdtcreator"
                target="_blank"
                rel="noopener noreferrer"
                className={cx('btn')}
              >
                Register Your Interest
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('Wrap')}>
        <div className={cx('WrapperContent')}>
          <p>
            You can also sign up through our Luma page
            <span>
              {' '}
              <a href="https://lu.ma/TheFutureofAIChapter2">here.</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Secure;
