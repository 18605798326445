import React from 'react';
import classNames from 'classnames/bind';
import vector from 'assets/images/event/Vector (13).png';

import bg1 from 'assets/images/sponsor/Background Artwork (1).png';
import bg2 from 'assets/images/event/Rectangle 47.png';
import bg3 from 'assets/images/event/Group 70.png';
import bg4 from 'assets/images/event/Group 71.png';

import styles from './Event.module.scss';

const cx = classNames.bind(styles);

function Event() {
  return (
    <div className={cx('Component')}>
      <img src={bg1} alt="" className={cx('BG1')} />
      <img src={bg2} alt="" className={cx('BG2')} />
      <img src={bg3} alt="" className={cx('BG3')} />
      <img src={bg4} alt="" className={cx('BG4')} />

      <div className={cx('Content')}>
        <div className={cx('Text')}>EVENT AGENDA</div>
      </div>
      <div className={cx('Wrapper')}>
        <div className={cx('Contain')}>
          <div className={cx('Box')}>
            <img src={vector} alt="" className={cx('image')} />
            <div className={cx('Title')}>
              <p className={cx('Text')}>Conference Agenda</p>
            </div>
            <div className={cx('BoxContent')}>
              <div className={cx('ContentBox1')}>
                <p className={cx('Time')}>8:30 AM - 9:00 AM</p>
                <p className={cx('Desc')}>Registration & Networking</p>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>9:00 AM - 9:02 AM | Speech</p>
                  <p className={cx('Note')}>Opening Remarks (Organizer)</p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Frank Lee, Founder & Managing Director, JDI
                </p>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Daryl Chung, Co-founder & BD Director, JDI
                </p>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>9:02 AM - 9:04 AM | Speech</p>
                  <p className={cx('Note')}>Opening Remarks (Co-host)</p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Norman Lim, President, Singapore Chamber of Commerce
                  Vietnam
                </p>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Frank Lee, Chair, Startups & Innovation Sub-Committee,
                  Singapore Chamber of Commerce Vietnam
                </p>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>9:04 AM - 9:09 AM | Speech</p>
                  <p className={cx('Note')}>
                    Welcome Address (Guest of Honour)
                  </p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Pang Te Cheng, Consul-General, Consulate-General of the
                  Republic of Singapore in Ho Chi Minh City
                </p>
              </div>
              <div className={cx('Line')} />
              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>9:09 AM - 9:15 AM | Speech</p>
                  <p className={cx('Note')}>
                    Welcome Address (Diamond Sponsor)
                  </p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Bernard L&apos;Allier, President, Sales, Cloud4C
                </p>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Mr. Phung Viet Thang, Country Manager, SMG, Intel Vietnam
                </p>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>9:15 AM - 9:45 AM | Presentation</p>
                  <p className={cx('Note')}>
                    Towards a Generative AI-empowered Ecosystem & Innovations
                  </p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Dr. Ng Aik Beng, Regional Manager, APS, NVIDIA AI Technology
                  Center, NVIDIA
                </p>
              </div>
              <div className={cx('Line')} />
              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    9:45 AM - 10:15 AM | Presentation
                  </p>
                  <p className={cx('Note')}>
                    Making AI Accessible to Southeast Asians
                  </p>
                  <p className={cx('text')}>
                    This session will delve into the strategies and innovations
                    designed to democratize artificial intelligence across
                    Southeast Asia. Learn how AI can be tailored to meet the
                    unique needs and challenges of the region, and explore
                    initiatives aimed at increasing AI literacy, infrastructure,
                    and adoption. Discover the potential of AI to drive economic
                    growth, improve quality of life, and foster inclusive
                    development in Southeast Asia.
                  </p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Dr. Leslie Teo, Senior Director, AI Products, AI Singapore
                </p>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    10:15 AM - 10:40 AM | Presentation
                  </p>
                  <p className={cx('Note')}>
                    Generative AI and it’s impact on technology sector
                  </p>
                  <p className={cx('text')}>
                    Explore how generative AI is revolutionizing the technology
                    sector and transforming business landscapes. Delve into
                    real-world applications, from intelligent automation to
                    innovative product development, and understand the profound
                    implications for future growth and competitive advantage.
                    This presentation will illuminate the path forward,
                    showcasing how businesses can leverage generative AI to
                    drive unprecedented innovation and operational excellence.
                  </p>
                </div>
                <p className={cx('Desc')}>
                  <span>Speaker: </span>
                  Dr. Nguyen Kim Anh, Chief Product Officer, VinBigData
                </p>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    10:40 AM - 11:15 AM | fireside chat
                  </p>
                  <p className={cx('Note')}>
                    Embracing AI Disruption: Insights from Cloud4C, CtrlS, and
                    Intel.
                  </p>
                  <p className={cx('text')}>
                    Join leaders from Intel and CtrlS-Cloud4C as they discuss
                    the transformative impact of accelerated computing, AI, and
                    cloud technologies. This session will explore the
                    intersection of these cutting-edge advancements and their
                    role in driving sustainable innovation across industries.
                    Embracing the latest strategies for leveraging AI and cloud
                    solutions to stay competitive in a rapidly evolving market,
                    and gain valuable insights into the future of technology
                    from some of the foremost experts in the field.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Mr. Chau Chieu Phuc, Engagement Manager, Investment &
                    Business Partners
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Chaitanya Reddy, Country Director, CLMV Markets, Cloud4C
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Himanshu Saluja, APJ Sales Strategy Specialist, Data
                    Center & AI, Intel
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    11:15 AM - 11:45 AM | fireside chat
                  </p>
                  <p className={cx('Note')}>
                    AI for Good: Leveraging technology for sustainable impact
                  </p>
                  <p className={cx('text')}>
                    Delve into the transformative power of artificial
                    intelligence and technology for good. Explore how AI is
                    being harnessed to drive sustainable development, improve
                    lives, and address pressing global challenges. From
                    innovative solutions in healthcare and education to
                    advancements in environmental conservation and social
                    equity, this session will illuminate the pivotal role AI
                    plays in shaping a better, more sustainable future for all.
                    Gain insights into real-world applications and collaborative
                    efforts that are making a significant impact, and discover
                    how you can contribute to this vital mission.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Ms. Thuy Hoang, Co-Pioneer & COO, The Sentry
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Cuong Dang, Founder & Excutive Board Director, Forbes
                    Vietnam
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />
              {/* <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    11:25 AM - 11:55 AM | Fireside Chat
                  </p>
                  <p className={cx('Note')}>Topic to be confirmed</p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    To be confirmed
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    To be confirmed
                  </p>
                </div>
              </div> */}
              {/* <div className={cx('Line')} /> */}
              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    11:45 AM - 12:30 AM | Panel Discussion
                  </p>
                  <p className={cx('Note')}>
                    Revolutionizing Retail: The Power of AI in Shaping the
                    Future of Shopping
                  </p>
                  <p className={cx('text')}>
                    Discover how artificial intelligence is revolutionizing the
                    retail industry. Learn about AI-driven technologies that
                    enhance customer experiences, optimize supply chains, and
                    drive personalized marketing strategies. This session will
                    provide valuable insights into the innovative ways AI is
                    transforming retail, offering actionable strategies for
                    businesses to stay competitive and deliver exceptional value
                    to their customers.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Mr. Frank Lee, Founder & Managing Director, JDI
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Tran Phuong Chi (Alexis), Deputy CEO, Phong Vu
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Oliver Tan, Co-founder & CEO, ViSenze
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Clara Lim, Managing Director, WIZ.AI
                  </p>
                  {/* <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    To be confirmed
                  </p> */}
                </div>
              </div>
              <div className={cx('Line')} />
              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    12:30 PM - 1:30 PM | Break & Networking
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />

              {/* <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>1:20 PM - 1:45 PM | Presentation</p>
                </div>
                <p className={cx('Note')}>To be confirmed</p>
              </div>
              <div className={cx('Line')} /> */}

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    1:30 PM - 2:15 PM | Fireside Chat
                  </p>
                  <p className={cx('Note')}>
                    Unravelling Vietnam Innovation Challenge 2024: Growing local
                    and going global with AI and Semicon
                  </p>
                  <p className={cx('text')}>
                    Dive into the dynamic intersection of artificial
                    intelligence, open innovation, and the burgeoning
                    semiconductor industry in Vietnam. Explore how AI-driven
                    advancements are transforming the semiconductor sector,
                    fostering a culture of open innovation, and positioning
                    Vietnam as a pivotal player in the global tech landscape.
                    Gain insights on the latest trends, challenges, and
                    opportunities, and discuss how collaborative efforts and
                    technological innovations can drive sustainable growth and
                    competitive advantage in Vietnam’s tech ecosystem.
                    <br />
                    <span>
                      There will also be a special announcement on the Vietnam
                      Innovation Challenge 2024.
                    </span>
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Mr. Doan Huu Duc, Founder and Managing Partner, Vietnam
                    Consulting Group
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Dr. Quan Le, Senior Faculty of Engineering, Fulbright
                    University Vietnam
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Pham The Hung (Jason), Head of Fundraising and Events,
                    Startup Vietnam Foundation
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ph.D. William H. Nguyen, Founder & CEO, Quickom
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Linh Nguyen, Representative, Meta Inc.
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />

              {/* <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    1:45 PM - 2:15 PM | fireside chat
                  </p>
                  <p className={cx('Note')}>
                    Unravelling Vietnam&apos;s Innovation Challenge: Growing
                    local and going global with AI and Semicon
                  </p>
                  <p className={cx('text')}>
                    Dive into the dynamic intersection of artificial
                    intelligence, open innovation, and the burgeoning
                    semiconductor industry in Vietnam. Explore how AI-driven
                    advancements are transforming the semiconductor sector,
                    fostering a culture of open innovation, and positioning
                    Vietnam as a pivotal player in the global tech landscape.
                    Gain insights on the latest trends, challenges, and
                    opportunities, and discuss how collaborative efforts and
                    technological innovations can drive sustainable growth and
                    competitive advantage in Vietnam’s tech ecosystem.
                    <br />
                    <span>
                      There will also be a special announcement on the Vietnam
                      Innovation Challenge 2024.
                    </span>
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    To be confirmed
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Pham The Hung (Jason), Head of Fundraising and Events,
                    Startup Vietnam Foundation
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    To be confirmed
                  </p>
                </div>
              </div>
              <div className={cx('Line')} /> */}

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    2:15 PM - 2:45 PM | fireside chat
                  </p>
                  <p className={cx('Note')}>
                    Bringing Jarvis, Joi, and John-117 to life through open
                    source AI
                  </p>
                  <p className={cx('text')}>
                    Explore the fascinating realm of human-machine collaboration
                    with a visionary founding team. Through their innovative
                    approach to open-source AI, they discuss their mission to
                    bring iconic AI personas like Jarvis from Iron Man, Joi from
                    Blade Runner, and John-117 from Halo to life. Gain unique
                    insights into the future of AI and its potential to reshape
                    our interactions with technology, blurring the lines between
                    fiction and reality.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Ms. Rebecca Joy, Founder & CEO, Saigon A.I.
                  </p>

                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Nicole Zhu, Co-founder, Homebrew Computer Company
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    2:45 PM - 3:15 PM | Fireside Chat
                  </p>
                  <p className={cx('Note')}>
                    Game changers: Revolutionizing sports and entertainment with
                    AI
                  </p>
                  <p className={cx('text')}>
                    Explore the transformative role of AI in sports and
                    entertainment. Discover how cutting-edge AI technologies are
                    reshaping the landscape of basketball and revolutionizing
                    the fan experience. From player performance analysis to
                    immersive virtual experiences, dive into the exciting
                    intersection of AI, sports, and entertainment and learn how
                    it&apos;s shaping the future of the game.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Mr. Daryl Chung, Co-founder & BD Director, JDI
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Tran Chu Sa, Chief Executive Officer, Vietnam Basketball
                    Association
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    3:15 PM - 4:00 PM | Panel Discussion
                  </p>
                  <p className={cx('Note')}>
                    Futureproofing Education: AI&apos;s role in shaping
                    today&apos;s classroom and tomorrow&apos;s workforce
                  </p>
                  <p className={cx('text')}>
                    Explore the transformative potential of artificial
                    intelligence in revolutionizing education across all levels
                    of learning, from K-12 to Institutes of Higher Learning
                    (IHLs) and professional development. Delve into discussions
                    on personalized learning, adaptive assessments, and
                    AI-powered educational tools, and discover how these
                    innovations are reshaping the future of learning and
                    teaching.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Mr. Daryl Chung, Co-founder & BD Director, JDI
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Nguyen Viet Trung, Deputy CEO, KDI Education
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Ruby Nguyen, Founder & CEO, Curieous
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Dr. Le Hoanh Su, Dean, Faculty of Information Systems,
                    University of Economics & Law
                  </p>

                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Thai Van Linh, Chief Executive Officer, Skills
                    Bridge/Senior Advisor, Openspace Ventures
                  </p>
                </div>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    4:00 PM - 4:45 PM | Panel Discussion
                  </p>
                  <p className={cx('Note')}>
                    Banking on AI: Unveiling the future of finance with
                    strategic insight and cutting-edge innovation
                  </p>
                  <p className={cx('text')}>
                    Explore the transformative impact of artificial intelligence
                    on the BFSI sector. This panel will delve into how AI is
                    revolutionizing fraud detection, enhancing customer service,
                    and optimizing risk management. Gain valuable insights into
                    the future of banking, financial services, and insurance in
                    the AI era, and discover strategic approaches to harnessing
                    AI for innovation and growth.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Ms. Ho Thanh Ton Vi, Founder & Managing Director, Beyond
                    Creative Agency
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Nhu Le, Executive Director, Head of Technology Strategy,
                    Standard Chartered Bank
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Rachel Nguyen, Chief Digital & Technology Officer, CIMB
                    Vietnam
                  </p>
                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Mauro Figueiredo, Former Director, MarTech & Center of
                    Excellence, Techcombank
                  </p>
                  {/* <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    To be confirmed
                  </p> */}
                </div>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>
                    4:45 PM - 5:15 PM | Panel Discussion
                  </p>
                  <p className={cx('Note')}>
                    Investing in artificial intelligence: Trends, opportunities,
                    and risks
                  </p>
                  <p className={cx('text')}>
                    Explore the dynamic landscape of investing in artificial
                    intelligence with leading VCs based in Vietnam. This panel
                    discussion will delve into the latest trends, emerging
                    opportunities, and inherent risks in AI investment. Gain
                    insights from seasoned investors on how AI is reshaping
                    various industries, the criteria they use to evaluate AI
                    startups, and the potential pitfalls to avoid. Whether
                    you&apos;re an entrepreneur seeking investment or an
                    investor looking to expand your portfolio, this session will
                    provide valuable perspectives on navigating the AI
                    investment ecosystem.
                  </p>
                </div>
                <div className={cx('BoxSpeak')}>
                  <p className={cx('Desc')}>
                    <span>Moderator: </span>
                    Mr. Hussein Sulaiman, Investment Principal, Capital JDI
                  </p>

                  <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Ms. Carol Cheung, Venture Partner, Cocoon Capital
                  </p>

                  {/* <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    Mr. Sohun Bae, Principal, AVV
                  </p> */}

                  {/* <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    To be confirmed
                  </p> */}

                  {/* <p className={cx('Desc')}>
                    <span>Speaker: </span>
                    To be confirmed
                  </p> */}
                </div>
              </div>
              <div className={cx('Line')} />

              <div className={cx('ContentBox')}>
                <div className={cx('BoxTime')}>
                  <p className={cx('Time')}>5:15 PM - 5:30 PM | Speech</p>
                  <p className={cx('Note2')}>
                    Lucky Draw, Appreciation, Closing Remarks
                  </p>
                </div>
              </div>
            </div>
            <div className={cx('Launch')}>
              <div className={cx('Title')}>
                <p className={cx('Text')}>Launch Zone Agenda</p>
              </div>
              <div className={cx('UpdateSoon')}>
                <p>Update Soon</p>
              </div>
            </div>

            <div className={cx('Talent')}>
              <div className={cx('Title')}>
                <p className={cx('Text')}>Talent Zone Agenda</p>
              </div>
              <div className={cx('UpdateSoon')}>
                <p>Update Soon</p>
              </div>
            </div>

            <div className={cx('Cap')}>
              <div className={cx('Title')}>
                <p className={cx('Text')}>Capital Zone Agenda</p>
              </div>
              <div className={cx('UpdateSoon')}>
                <p>Update Soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Event;
