export const LOCATION = {
  HOME: '/',

  OPEN_AI: '##',
  CHAT_GPT: '##',
  GPT_4: '##',
  DALL_E_2: '##',
  GENERATIVE_AI: '##',

  FACEBOOK: 'https://www.facebook.com/JumpstartDisruptiveInnovations',
  LINKEDIN: 'https://www.linkedin.com/company/13217677',
};

export const SITE_NAME = 'AI JDI';
