import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import bg from 'assets/images/pressmedia/Artwork.png';

import pre1 from 'assets/images/pressmedia/pre1.png';
import pre2 from 'assets/images/pressmedia/pre2.png';
import pre3 from 'assets/images/pressmedia/pre3.png';
import pre4 from 'assets/images/pressmedia/pre4.png';
import pre5 from 'assets/images/pressmedia/pre5.png';
import pre6 from 'assets/images/pressmedia/pre6.png';
import pre7 from 'assets/images/pressmedia/pre7.png';
import pre8 from 'assets/images/pressmedia/pre8.png';
import pre9 from 'assets/images/pressmedia/pre9.png';

import styles from './PressMedia.module.scss';

const cx = classNames.bind(styles);

function PressMedia() {
  return (
    <div className={cx('Component')}>
      <img src={bg} alt="" className={cx('BG')} />
      <div className={cx('Content')}>
        <p className={cx('Title')}>Past Year’s Media</p>
        <p className={cx('Text')}>Post Event Interview & Media</p>
      </div>
      <div className={cx('WrapperDesktop')}>
        <div className={cx('Col')}>
          <a
            className={cx('CardItem')}
            href="https://www.htv.com.vn/hoi-thao-xu-huong-phat-trien-cua-ai-trong-tuong-lai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              {' '}
              <img src={pre1} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>HTV</div>
              <div className={cx('Desc')}>
                Conference on the Future Development of Artificial Intelligence
              </div>
            </div>
          </a>

          <a
            className={cx('CardItem')}
            href="https://nhipcaudautu.vn/cong-nghe/chuyen-gia-chia-se-ung-dung-ai-vao-kinh-doanh-tai-su-kien-tuong-lai-cua-tri-tue-nhan-tao-3354233/?fbclid=IwAR3BJrwB1ZpYUaLGOL7MsewC2OFYtVpJcmeimT-1UKv_KRIf2wRDzMD0lnY"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre2} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>Tap Chi Nhip Cau Dau Tu</div>
              <div className={cx('Desc')}>
                Applications of Artificial Intelligence in Business: Insights
                from Industry Experts at &rsquo;The Future of AI&rsquo; Event
              </div>
            </div>
          </a>

          <a
            className={cx('CardItem')}
            href="https://thanhnien.vn/tuong-lai-cua-tri-tue-nhan-tao-185230729135013403.htm?fbclid=IwAR3BlSDrnlB11ySez7Z5MTt0-unsotV36TG9GLrYVPX4MpdEtDyvqQpdFYw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre3} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>Thanh Nien </div>
              <div className={cx('Desc')}>
                The Future of Artificial Intelligence
              </div>
            </div>
          </a>
        </div>

        <div className={cx('Col')}>
          <a
            className={cx('CardItem')}
            href="https://isc-vietnam.com/isc-dong-hanh-cung-su-kien-tuong-lai-cua-tri-tue-nhan-tao/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre4} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>ISC Vietnam</div>
              <div className={cx('Desc')}>
                ISC Partners with the &rsquo;Future of Artificial
                Intelligence&rsquo; Event
              </div>
            </div>
          </a>

          <a
            className={cx('CardItem')}
            href="https://www.elleman.vn/tin-tuc/phat-trien-ai-2023-elleman?fbclid=IwAR3mDz5oJUDYrjfT74Us_SBDdysJpdHOn3l19M0wH6ecAGW165d6CUX8A9E"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre5} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>Elleman </div>
              <div className={cx('Desc')}>
                AI Industry Experts Outline the Development of Artificial
                Intelligence in Vietnam
              </div>
            </div>
          </a>

          <a
            className={cx('CardItem')}
            href="http://www.thoibaothuongmai.com/2023/07/chuyen-gia-au-nganh-ve-ai-ua-ra-loat.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre6} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>Thoi Bao Thuong Mai </div>
              <div className={cx('Desc')}>
                AI Industry Experts Outline the Development of Artificial
                Intelligence in Vietnam
              </div>
            </div>
          </a>
        </div>

        <div className={cx('Col')}>
          <a
            className={cx('CardItem')}
            href="https://www.thuonghieuvacuocsong.com.vn/2023/07/chuyen-gia-au-nganh-ve-ai-ua-ra-loat.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre7} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>Thuong Hieu & Cuoc Song</div>
              <div className={cx('Desc')}>
                AI Industry Experts Outline the Development of Artificial
                Intelligence in Vietnam
              </div>
            </div>
          </a>

          <a
            className={cx('CardItem')}
            href="https://cafetek.vn/hoi-thao-xu-huong-phat-trien-cua-ai-trong-tuong-lai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre8} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>CafeTek </div>
              <div className={cx('Desc')}>
                Conference on the Future Development of Artificial Intelligence
              </div>
            </div>
          </a>

          <a
            className={cx('CardItem')}
            href="https://thuonghieuvaphapluat.vn/chuyen-gia-dau-nganh-ve-ai-dua-ra-loat-phac-hoa-ve-su-phat-trien-cua-tri-tue-nhan-tao-tai-viet-nam-d62025.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={cx('WrapImage')}>
              <img src={pre9} alt="" className={cx('Image')} />
            </div>
            <div className={cx('Info')}>
              <div className={cx('Name')}>Thuong Hieu & Phap Luat </div>
              <div className={cx('Desc')}>
                AI Industry Experts Outline the Development of Artificial
                Intelligence in Vietnam
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className={cx('WrapperMobile')}>
        <Swiper
          className={cx('SwiperPartner')}
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          slidesPerGroup={1}
          loop
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide className={cx('slide')}>
            <a
              className={cx('CardItem')}
              href="https://www.htv.com.vn/hoi-thao-xu-huong-phat-trien-cua-ai-trong-tuong-lai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                {' '}
                <img src={pre1} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>HTV</div>
                <div className={cx('Desc')}>
                  Conference on the Future Development of Artificial
                  Intelligence
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://nhipcaudautu.vn/cong-nghe/chuyen-gia-chia-se-ung-dung-ai-vao-kinh-doanh-tai-su-kien-tuong-lai-cua-tri-tue-nhan-tao-3354233/?fbclid=IwAR3BJrwB1ZpYUaLGOL7MsewC2OFYtVpJcmeimT-1UKv_KRIf2wRDzMD0lnY"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre2} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>Tap Chi Nhip Cau Dau Tu</div>
                <div className={cx('Desc')}>
                  Applications of Artificial Intelligence in Business: Insights
                  from Industry Experts at &rsquo;The Future of AI&rsquo; Event
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://thanhnien.vn/tuong-lai-cua-tri-tue-nhan-tao-185230729135013403.htm?fbclid=IwAR3BlSDrnlB11ySez7Z5MTt0-unsotV36TG9GLrYVPX4MpdEtDyvqQpdFYw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre3} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>Thanh Nien </div>
                <div className={cx('Desc')}>
                  The Future of Artificial Intelligence
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://isc-vietnam.com/isc-dong-hanh-cung-su-kien-tuong-lai-cua-tri-tue-nhan-tao/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre4} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>ISC Vietnam</div>
                <div className={cx('Desc')}>
                  ISC Partners with the &rsquo;Future of Artificial
                  Intelligence&rsquo; Event
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://www.elleman.vn/tin-tuc/phat-trien-ai-2023-elleman?fbclid=IwAR3mDz5oJUDYrjfT74Us_SBDdysJpdHOn3l19M0wH6ecAGW165d6CUX8A9E"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre5} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>Elleman </div>
                <div className={cx('Desc')}>
                  AI Industry Experts Outline the Development of Artificial
                  Intelligence in Vietnam
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            {' '}
            <a
              className={cx('CardItem')}
              href="http://www.thoibaothuongmai.com/2023/07/chuyen-gia-au-nganh-ve-ai-ua-ra-loat.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre6} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>Thoi Bao Thuong Mai </div>
                <div className={cx('Desc')}>
                  AI Industry Experts Outline the Development of Artificial
                  Intelligence in Vietnam
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://www.thuonghieuvacuocsong.com.vn/2023/07/chuyen-gia-au-nganh-ve-ai-ua-ra-loat.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre7} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>Thuong Hieu & Cuoc Song</div>
                <div className={cx('Desc')}>
                  AI Industry Experts Outline the Development of Artificial
                  Intelligence in Vietnam
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://cafetek.vn/hoi-thao-xu-huong-phat-trien-cua-ai-trong-tuong-lai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre8} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>CafeTek </div>
                <div className={cx('Desc')}>
                  Conference on the Future Development of Artificial
                  Intelligence
                </div>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a
              className={cx('CardItem')}
              href="https://thuonghieuvaphapluat.vn/chuyen-gia-dau-nganh-ve-ai-dua-ra-loat-phac-hoa-ve-su-phat-trien-cua-tri-tue-nhan-tao-tai-viet-nam-d62025.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cx('WrapImage')}>
                <img src={pre9} alt="" className={cx('Image')} />
              </div>
              <div className={cx('Info')}>
                <div className={cx('Name')}>Thuong Hieu & Phap Luat </div>
                <div className={cx('Desc')}>
                  AI Industry Experts Outline the Development of Artificial
                  Intelligence in Vietnam
                </div>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default PressMedia;
