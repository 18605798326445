/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import Drawer from 'react-modern-drawer';
// import { Link } from 'react-scroll';

import { ReactComponent as Logo } from 'assets/images/Logo2.svg';
import xIcon from 'assets/icons/close-icon.png';
import tabBar from 'assets/icons/hamburger-icon.png';

import AppButton from 'components/AppButton';
import 'react-modern-drawer/dist/index.css';
import { useLocation, Link } from 'react-router-dom';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const NAV_LIST = [
  {
    id: '1',
    label: 'Home',
    link: '/',
  },
  {
    id: '2',
    label: 'Event Agenda',
    link: '/event',
  },
  {
    id: '3',
    label: 'Our Speakers',
    link: '/speaker',
  },
  {
    id: '4',
    label: 'Our Sponsors',
    link: '/sponsor',
  },
  {
    id: '5',
    label: 'Our Exhibitors',
    link: '/exhibitor',
  },
];

function Header() {
  // const [isSticky, setIsSticky] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const location = useLocation();

  const onToggleMenu = useCallback(
    () => setIsShowMenu((prevState) => !prevState),
    [],
  );

  // const onScroll = useCallback(() => {
  //   window.addEventListener('scroll', () => {
  //     const currentScroll = window.pageYOffset;

  //     if (currentScroll > 0) {
  //       setIsSticky(true);
  //     } else {
  //       setIsSticky(false);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', onScroll);
  //   }

  //   return () => window.addEventListener('scroll', onScroll);
  // }, [onScroll]);
  return (
    <>
      <div className={cx('Component')}>
        <div className={cx('Blur', 'Show')} />
        <div className={cx('Header', 'ComponentShow')}>
          <AppButton
            type="button"
            className={cx('TabBar')}
            onClick={onToggleMenu}
          >
            <img src={tabBar} alt="hambuger" />
          </AppButton>

          <a href="/#" aria-label="Some label">
            <Logo className={cx('Logo')} />
          </a>

          <ul className={cx('Nav')}>
            {NAV_LIST.map((item) => (
              <li key={item.id} className={cx('NavItem')}>
                <Link
                  className={cx('NavLink', {
                    [cx('Active')]: location.pathname === `/${item.link}`,
                  })}
                  spy
                  to={item.link}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>

          <a
            href="https://www.eventbrite.sg/e/the-future-of-artificial-intelligence-chapter-2-tickets-933089775637?aff=oddtdtcreator"
            target="_blank"
            className={cx('Btn')}
            rel="noreferrer"
          >
            Register Now
          </a>
        </div>
      </div>

      <Drawer
        open={isShowMenu}
        onClose={onToggleMenu}
        className={cx('Drawn')}
        direction="right"
        zInde={100}
        duration={300}
        lockBackgroundScroll
      >
        <div className={cx('Mobile')}>
          <AppButton
            type="button"
            isShowContentOnly
            className={cx('Icon')}
            onClick={onToggleMenu}
          >
            <img src={xIcon} alt="x icon" />
          </AppButton>

          <div className={cx('Wrapper')}>
            <ul className={cx('Nav')}>
              {NAV_LIST.map((item) => (
                <li
                  key={item.id}
                  className={cx('NavItem')}
                  onClick={onToggleMenu}
                  role="menuitem"
                  tabIndex={0}
                  onKeyDown={() => null}
                >
                  <Link
                    className={cx('NavLink', {
                      [cx('Active')]: location.pathname === `/${item.link}`,
                    })}
                    spy
                    to={item.link}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>

            <a
              href="https://www.eventbrite.sg/e/the-future-of-artificial-intelligence-chapter-2-tickets-933089775637?aff=oddtdtcreator"
              target="_blank"
              className={cx('Btn')}
              rel="noreferrer"
            >
              Register Now
            </a>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Header;
