import React from 'react';
import classNames from 'classnames/bind';
// import { useMediaQuery } from 'react-responsive';

// import vector from 'assets/images/event/Vector (13).png';
import bg1 from 'assets/images/sponsor/Background Artwork (1).png';
import bg2 from 'assets/images/sponsor/Background Artwork (2).png';
import ex1 from 'assets/images/sponsor/ex1.png';
import ex2 from 'assets/images/sponsor/ex2.png';
import ex3 from 'assets/images/sponsor/ex3.png';
import ex4 from 'assets/images/sponsor/ex4.png';
import ex5 from 'assets/images/sponsor/ex5.png';
import ex6 from 'assets/images/sponsor/ex6.png';
import ex7 from 'assets/images/sponsor/ex7.png';
import ex8 from 'assets/images/sponsor/ex8.png';
import ex9 from 'assets/images/sponsor/ex9.png';

import styles from './Exhibitor.module.scss';

const cx = classNames.bind(styles);

function Exhibitor() {
  const data = [
    {
      id: '1',
      image: ex7,
      name: 'BowNow',
      link: 'https://bow-now.com/',
    },

    {
      id: '2',
      image: ex2,
      name: 'EM&AI',
      link: 'https://emandai.net/vi/',
    },
    {
      id: '3',
      image: ex6,
      name: 'H3 Zoom',
      link: 'https://www.h3zoom.ai/',
    },
    {
      id: '4',
      image: ex9,
      name: 'hello health group',
      link: 'https://hellohealthgroup.com/',
    },
    {
      id: '5',
      image: ex8,
      name: 'interviewer.ai',
      link: 'https://interviewer.ai/vi/',
    },
    {
      id: '6',
      image: ex3,
      name: 'Moreh',
      link: 'https://moreh.io/',
    },
    {
      id: '7',
      image: ex4,
      name: 'NSpace',
      link: 'https://nspace.is/',
    },
    {
      id: '8',
      image: ex1,
      name: 'Tictag',
      link: 'https://www.tictag.io/',
    },
    {
      id: '9',
      image: ex5,
      name: 'Wiz.ai',
      link: 'https://www.wiz.ai/',
    },
  ];
  // const isDesktop = useMediaQuery({
  //   query: '(min-width: 1024px)',
  // });
  // const [currentPage, setCurrentPage] = useState(1);
  // const postsPerPage = isDesktop ? 9 : 6;
  // const pages = [];
  // for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i += 1) {
  //   pages.push(i);
  // }

  // const lastPostIndex = currentPage * postsPerPage;
  // const firstPostIndex = lastPostIndex - postsPerPage;
  // const currentPosts = data.slice(firstPostIndex, lastPostIndex);

  // const handleClick = (page) => {
  //   console.log(`Page clicked: ${page}`);
  //   setCurrentPage(page);
  // };

  return (
    <div className={cx('Component')}>
      <img src={bg1} alt="" className={cx('BG1')} />
      <img src={bg2} alt="" className={cx('BG2')} />
      <div className={cx('Content')}>
        <div className={cx('Text')}>OUR EXHIBITORS</div>
      </div>
      <div className={cx('Wrapper')}>
        {/* Your code here */}
        <div className={cx('Contain')}>
          {data.map((item) => (
            <div key={item.id} className={cx('ContainBox')}>
              <p className={cx('Title')}>Exhibitor</p>
              <div className={cx('Logo')}>
                <img src={item.image} alt="logo" className={cx('vngcloud1', { image4: item.id === '4' })} />
                <p className={cx('Name')}>{item.name}</p>
              </div>
              <div className={cx('btn')}>
                <a
                  href={item.link}
                  className={cx('Item')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website
                </a>
              </div>
            </div>
          ))}
        </div>
        {/* <div className={cx('Pagination')}>
          {pages.map((page) => (
            <button
              key={page}
              type="button"
              onClick={() => handleClick(page)}
              className={cx('page', { active: currentPage === page })}
            >
              {String(page).padStart(2, '0')}
            </button>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Exhibitor;
