import React from 'react';
import classNames from 'classnames/bind';

import Banner from './Banner';

import styles from './Home.module.scss';
import Info from './Info';
import MediaPartner from './MediaPartner';
import Topic from './Topic';
import Speaker from './Speaker';
import Unveil from './Unveil';
// import PastSpeaker from './PastSpeaker';
// import PastMediaPartner from './PastMediaPartner';
import Recap from './Recap';
import PressMedia from './PressMedia';
import Secure from './Secure';
import Testimonial from './Testimonial';

const cx = classNames.bind(styles);

function Home() {
  return (
    <div className={cx('Home')}>
      <div id="event">
        <Banner />
        <Unveil />
        <Info />
        <Topic />
        <Speaker />
        <MediaPartner />
        {/* <PastSpeaker /> */}
        {/* <PastMediaPartner /> */}
        <Recap />
        <Testimonial />
        <PressMedia />
        <Secure />
      </div>
    </div>
  );
}

export default Home;
