import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import back from 'assets/images/speaker/back.svg';
import next from 'assets/images/speaker/next.svg';

import avt from 'assets/images/testimonial/Group 67.png';
import avt2 from 'assets/images/testimonial/avt2.png';
import avt3 from 'assets/images/testimonial/avt3.png';
import avt4 from 'assets/images/testimonial/avt4.png';
import avt5 from 'assets/images/testimonial/avt5.png';
import avt6 from 'assets/images/testimonial/avt6.png';
import avt7 from 'assets/images/testimonial/avt7.png';

import vector from 'assets/images/testimonial/Vector15.png';
import logo from 'assets/images/testimonial/Isolation_Mode.png';
import ai from 'assets/images/testimonial/AI.svg';
import chapter2 from 'assets/images/testimonial/chapter2.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './Testimonial.module.scss';

const cx = classNames.bind(styles);

const TEST_LIST = [
  {
    id: '1',
    image: avt2,
    name: 'Mr. Quan Nguyen',
    title: 'Research Engineer |  ',
    spanTitle: 'Open AI',
    text: 'The event has been exceptionally professional, successfully connecting everyone from businesses to AI Experts.',
    link: '##',
    classname: 'test1',
  },
  {
    id: '2',
    image: avt3,
    name: 'Mr. Greg Ohan',
    title: 'CEO | ',
    spanTitle: 'The Sentry',
    text: 'The event was fantastic! I think it’s really well put together, in terms of the quality, the execution and also the content.',
    link: '##',
    classname: 'test2',
  },
  {
    id: '3',
    image: avt,
    name: 'Dr. Charles Cheung',
    title: 'Deputy Director',
    desc: 'NVIDIA AI Technology Center',
    text: 'I think the event was well-organized. There were a lot of guests attending the event, the time management & organizing were very well done.',
    link: '##',
    classname: 'test3',
  },
  {
    id: '4',
    image: avt4,
    name: 'Dr. Nguyen Kim Anh',
    title: 'CPO | ',
    spanTitle: 'VinBigData',
    text: 'This is a great event, I will definitely participate if invited again for similar events in the future.',
    link: '##',
    classname: 'test4',
  },
  {
    id: '5',
    image: avt5,
    name: 'Mr. Tuan Huy Bui',
    title: 'Growth Specialist |  ',
    spanTitle: 'VNG Cloud',
    text: 'I had the chance to connect with many key stakeholders and investors in the AI industry and engage with outstanding students who are also enthusiastic about learning how AI is shaping the future.',
    link: '##',
    classname: 'test5',
  },
  {
    id: '6',
    image: avt6,
    name: 'Mr. Jeremy Lee',
    title: 'Business Development & Partnership |  ',
    spanTitle: 'Wiz.AI',
    text: 'We are very appreciative to be here at this event in Ho Chi Minh, and I think we have made a great impact in term of meeting new people from this event.',
    link: '##',
    classname: 'test6',
  },
  {
    id: '7',
    image: avt7,
    name: 'Mr. Wahat Bastimiyev',
    title: 'Founder & CEO |  ',
    spanTitle: 'Verigram',
    text: 'There are not many event about AI in Vietnam, and I must say that you guys are doing a great job. If JDI organizes another event like this in the future, I will definitely participate.',
    link: '##',
    classname: 'test6',
  },
];

function Testimonial() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex;
    const totalSlides = swiper.slides.length;

    for (let i = 0; i < totalSlides; i += 1) {
      const distanceFromMiddle = i - currentIndex;

      const scale = 0.8 ** Math.abs(distanceFromMiddle);

      const slide = swiper.slides[i];
      slide.style.transition = '';
      slide.style.transform = `scale(${scale})`;

      if (Math.abs(distanceFromMiddle) === 0) {
        slide.style.transition = 'transform 0.3s ease';
        slide.style.opacity = 1;
        slide.style.filter = 'none';
      } else {
        slide.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
        slide.style.transform = 'scale(0.72)';
        slide.style.opacity = 0.8;
        slide.style.filter = 'blur(4px)';
      }
    }
    setCurrentSlide(currentIndex);
  };

  const index = 0;
  return (
    <div className={cx('Component')}>
      <div className={cx('Content')}>
        <div className={cx('Title')}>Testimonials</div>
      </div>

      <div className={cx('WrapperDesktop')}>
        <Swiper
          navigation={{
            nextEl: '#backtopic',
            prevEl: '#nexttopic',
          }}
          modules={[Navigation]}
          className={cx('Swiper')}
          slidesPerView={3}
          slidesPerGroup={1}
          spaceBetween={3.9}
          centeredSlides
          loop
          initialSlide={currentSlide}
          centerInsufficientSlides
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          {TEST_LIST.map((item) => (
            <SwiperSlide key={item.id}>
              <div
                className={cx('Contain', {
                  SlideActive: index === currentSlide,
                  SlidePrev: index === currentSlide - 1,
                  SlideNext: index === currentSlide + 1,
                })}
              >
                <div className={cx('WrapperCard')}>
                  <div className={cx('Card')}>
                    <img src={vector} alt="" className={cx('image')} />
                    <div className={cx('WrapperContent')}>
                      <div className={cx('Content')}>
                        <div className={cx('Header')}>
                          <img src={logo} alt="" className={cx('logo')} />
                          <div className={cx('WrapperText2')}>
                            <p className={cx('Text')}>The Future of</p>
                            <img src={ai} alt="" className={cx('ai')} />
                            <img
                              src={chapter2}
                              alt=""
                              className={cx('chapter2')}
                            />
                          </div>
                        </div>

                        <div className={cx('Body')}>
                          <div className={cx('BoxText')}>
                            <p className={cx('text')}>{item.text}</p>
                          </div>

                          <div className={cx('BoxInfo')}>
                            <img src={item.image} alt="" />
                            <div className={cx('Info')}>
                              <p className={cx('Name')}>{item.name}</p>
                              <div className={cx('Role')}>
                                <p className={cx('title')}>
                                  {item.title}
                                  <span className={cx('spanTitle')}>
                                    {item.spanTitle}
                                  </span>
                                </p>
                                <p className={cx('Desc')}>{item.desc}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <img src={back} alt="back" className={cx('Back')} id="backtopic" />
        <img src={next} alt="next" className={cx('Next')} id="nexttopic" />
      </div>

      <div className={cx('WrapperMobile')}>
        <Swiper
          modules={[Pagination]}
          className={cx('Swiper')}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
        >
          {TEST_LIST.map((item) => (
            <SwiperSlide key={item.id}>
              <div className={cx('Cover')}>
                <div
                  className={cx('Contain', {
                    SlideActive: index === currentSlide,
                    SlidePrev: index === currentSlide - 1,
                    SlideNext: index === currentSlide + 1,
                  })}
                >
                  <div className={cx('WrapperCard')}>
                    <div className={cx('Card')}>
                      <img src={vector} alt="" className={cx('image')} />
                      <div className={cx('WrapperContent')}>
                        <div className={cx('Content')}>
                          <div className={cx('Header')}>
                            <img src={logo} alt="" className={cx('logo')} />
                            <div className={cx('WrapperText2')}>
                              <p className={cx('Text')}>The Future of</p>
                              <img src={ai} alt="" className={cx('ai')} />
                              <img
                                src={chapter2}
                                alt=""
                                className={cx('chapter2')}
                              />
                            </div>
                          </div>

                          <div className={cx('Body')}>
                            <div className={cx('BoxText')}>
                              <p className={cx('text')}>{item.text}</p>
                            </div>

                            <div className={cx('BoxInfo')}>
                              <img
                                src={item.image}
                                alt=""
                                className={cx('imageInfo')}
                              />
                              <div className={cx('Info')}>
                                <p className={cx('Name')}>{item.name}</p>
                                <div className={cx('Role')}>
                                  <p className={cx('title')}>
                                    {item.title}
                                    <span className={cx('spanTitle')}>
                                      {item.spanTitle}
                                    </span>
                                  </p>
                                  <p className={cx('Desc')}>{item.desc}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Testimonial;
