import React from 'react';
// import ReactPlayer from 'react-player';

import classNames from 'classnames/bind';
import bg from 'assets/images/recap/Background Artwork.png';
import vector1 from 'assets/images/recap/Vector (5).png';
import vector2 from 'assets/images/recap/Vector (6).png';
import vector3 from 'assets/images/recap/Vector (7).png';

import styles from './Recap.module.scss';

const cx = classNames.bind(styles);

function Recap() {
  return (
    <div className={cx('Component')}>
      <img src={bg} alt="" className={cx('BG')} />
      <div className={cx('Wrapper')}>
        <div className={cx('Box')}>
          <img src={vector1} alt="" className={cx('vector1')} />
          <img src={vector2} alt="" className={cx('vector2')} />
          <img src={vector3} alt="" className={cx('vector3')} />
          <div id="video-container" className={cx('video')}>
            <iframe
              src="https://www.youtube.com/embed/EKpdagD1hYw?si=R9zxJJiQZxrhrDCl"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className={cx('video')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recap;
