/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { ReactComponent as Card1 } from 'assets/images/card-1.svg';
import { ReactComponent as Card2 } from 'assets/images/card-2.svg';
import { ReactComponent as Card3 } from 'assets/images/card-3.svg';

import bg1 from 'assets/images/Vector.png';
import bg2 from 'assets/images/Vector-1.png';
import bg3 from 'assets/images/Vector-2.png';
import { ReactComponent as Vector1 } from 'assets/svg/vector1.svg';
import { ReactComponent as Vector2 } from 'assets/svg/vector2.svg';

import styles from './Info.module.scss';

const cx = classNames.bind(styles);

function Info() {
  return (
    <div className={cx('Component')}>
      <div className={cx('Banner')}>
        <Vector1 />
        <div className={cx('WrapperBox')}>
          <div className={cx('Box')}>
            <p className={cx('title')}>500+</p>
            <p className={cx('content')}>Attendees</p>
          </div>

          <div className={cx('Box')}>
            <p className={cx('title')}>40</p>
            <p className={cx('content')}>Global Speakers</p>
          </div>

          <div className={cx('Box')}>
            <p className={cx('title')}>20</p>
            <p className={cx('content')}>Exhibitors</p>
          </div>

          <div className={cx('Box')}>
            <p className={cx('title')}>13</p>
            <p className={cx('content')}>Conference Sessions</p>
          </div>

          {/* <div className={cx('Box')}>
            <p className={cx('title')}>5</p>
            <p className={cx('content')}>Workshops</p>
          </div> */}

          <div className={cx('Box')}>
            <p className={cx('title')}>3</p>
            <p className={cx('content')}>Business Zones</p>
          </div>
        </div>
        <div className={cx('WrapperMobile')}>
          <Swiper
            className={cx('SwiperPartner')}
            modules={[Pagination, Autoplay]}
            slidesPerView={1}
            slidesPerGroup={1}
            loop
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide className={cx('slide')}>
              <div className={cx('CardItem')}>
                <div className={cx('Box')}>
                  <p className={cx('title')}>500+</p>
                  <p className={cx('content')}>Attendees</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className={cx('CardItem')}>
                <div className={cx('Box')}>
                  <p className={cx('title')}>40</p>
                  <p className={cx('content')}>Global Speakers</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className={cx('CardItem')}>
                <div className={cx('Box')}>
                  <p className={cx('title')}>30</p>
                  <p className={cx('content')}>Exhibitors</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className={cx('CardItem')}>
                <div className={cx('Box')}>
                  <p className={cx('title')}>13</p>
                  <p className={cx('content')}>Conference Sessions</p>
                </div>
              </div>
            </SwiperSlide>

            {/* <SwiperSlide>
              <div className={cx('CardItem')}>
                <div className={cx('Box')}>
                  <p className={cx('title')}>5</p>
                  <p className={cx('content')}>Workshops</p>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
        <Vector2 />
      </div>

      <div className={cx('Content')}>
        <div className={cx('Title')}>A Special Gathering To</div>

        <div className={cx('List')}>
          <div className={cx('ListItem')}>
            <p className={cx('number')}>01.</p>
            <div className={cx('content')}>
              <span>Discuss</span>
              <p>
                {' '}
                the latest AI trends in Vietnam, Singapore & Southeast Asia
              </p>
            </div>
          </div>
          <div className={cx('ListItem')}>
            <p className={cx('number')}>02.</p>
            <div className={cx('content')}>
              <span>Cross</span>
              <p>
                {' '}
                pollination of knowledge, technology, capital, and resources
              </p>
            </div>
          </div>
          <div className={cx('ListItem')}>
            <p className={cx('number')}>03.</p>
            <div className={cx('content')}>
              <span>Identify</span>
              <p> talents and experts in AI & Technology </p>
            </div>
          </div>
          <div className={cx('ListItem')}>
            <p className={cx('number')}>04.</p>
            <div className={cx('content')}>
              <span> Showcase </span>
              <p>emerging technologies in AI & Innovations </p>
            </div>
          </div>
        </div>
      </div>

      <div className={cx('Agenda')}>
        <div className={cx('AgendaTitle')}>AGENDA</div>

        <div className={cx('AgendaContent')}>
          <img src={bg1} alt="" className={cx('Bg1')} />
          <img src={bg2} alt="" className={cx('Bg2')} />
          <img src={bg3} alt="" className={cx('Bg3')} />

          <div className={cx('Col')}>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>8:30 AM - 9:00 AM</div>

              <div className={cx('Decs')}>
                Registration, Exhibition, Networking
              </div>
            </div>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>9:00 AM - 9:05 AM</div>

              <div className={cx('Decs')}>Opening Remarks</div>
            </div>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>9:05 AM - 9:15 AM</div>

              <div className={cx('Decs')}>Welcome Addresses</div>
            </div>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>9:15 AM - 12:30 PM</div>

              <div className={cx('Decs')}>
                Conference, Exhibition, Business Lounge
              </div>
            </div>
          </div>

          <div className={cx('Col')}>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>12:30 PM - 01:30 PM</div>

              <div className={cx('Decs')}>Break</div>
            </div>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>01:30 PM - 05:15 PM</div>

              <div className={cx('Decs')}>
                Conference, Exhibition, Business Lounge
              </div>
            </div>
            <div className={cx('Wrapper')}>
              <div className={cx('Title')}>05:15 PM - 05:30 PM</div>

              <div className={cx('Decs')}>Closing Remarks & Lucky Draw</div>
            </div>
          </div>
        </div>
      </div>

      <div className={cx('Btn')}>
        <a href="/event" className={cx('BtnItem')}>
          Access Full Agenda
        </a>
      </div>
    </div>
  );
}

export default Info;
