import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { LOCATION } from 'constants/index';

import Home from 'pages/Home';
import Layout from 'Layout';
import Event from 'pages/Event';
import Speaker from 'pages/Speaker';

import './App.scss';
import Sponsor from 'pages/Sponsor';
import Exhibitor from 'pages/Exhibitor';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOCATION.HOME} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="event" element={<Event />} />
          <Route path="speaker" element={<Speaker />} />
          <Route path="sponsor" element={<Sponsor />} />
          <Route path="exhibitor" element={<Exhibitor />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
