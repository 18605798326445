import React from 'react';
import classNames from 'classnames/bind';
import Countdown from 'react-countdown';
import { ReactComponent as Chapter2Desktop } from 'assets/svg/frame118desktop.svg';
import { ReactComponent as Chapter2Mobile } from 'assets/svg/frame118mobile.svg';

import styles from './Banner.module.scss';

const cx = classNames.bind(styles);

const dateCountDown = new Date(2024, 6, 12, 8, 30, 0, 0);

function Banner() {
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
  }) => (
    <>
      <div className={cx('Day')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{days}</span>
        <p>DAY</p>
      </div>
      <div className={cx('Hour')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{hours}</span>
        <p>HOUR</p>
      </div>
      <div className={cx('Minute')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{minutes}</span>
        <p>MINUTE</p>
      </div>
      <div className={cx('SECOND')}>
        <div className={cx('Blur')}>
          <div className={cx('Bg')} />
          <div className={cx('Filter')} />
        </div>
        <span>{seconds}</span>
        <p>SECOND</p>
      </div>
    </>
  );

  return (
    <div className={cx('Component')}>
      <div className={cx('Content')}>
        <div className={cx('Text')}>The Future of</div>

        <div className={cx('TextGradient')}>
          <span>ARTIFICIAL</span>
          {'\n'}
          INTELLIGENCE
        </div>

        {/* <div className={cx('TextLocation')}>
          Ho Chi Minh City
          {' '}
          <span>|</span>
          {' '}
          28 July 2023
        </div> */}
      </div>

      <div>
        <Chapter2Desktop className={cx('Chapter2Desktop')} />
      </div>

      <div>
        <Chapter2Mobile className={cx('Chapter2Mobile')} />
      </div>

      <div className={cx('WrapperLocationAndTime')}>
        <p className={cx('Location')}>New World Saigon Hotel, Ho Chi Minh City</p>
        <p className={cx('Time')}>  12 July 2024 | 8:30 - 5:30 GMT +7</p>
      </div>

      <div className={cx('Wrapper')}>
        <div className={cx('CountDown')}>
          <Countdown date={dateCountDown} renderer={renderer} />
        </div>

        <div className={cx('Btn')}>
          <a
            href="https://www.eventbrite.sg/e/the-future-of-artificial-intelligence-chapter-2-tickets-933089775637?aff=oddtdtcreator"
            target="_blank"
            className={cx('BtnItem')}
            rel="noreferrer"
          >
            Register Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default Banner;
