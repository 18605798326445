/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './AppButton.module.scss';

const cx = classNames.bind(styles);

function AppButton(props) {
  const {
    isDisabled,
    isLoading,
    isShowContentOnly,

    type,
    className,
    callbackParams,

    onCallback,
    onClick,

    children,
    ...rest
  } = props;

  const onClickBtn = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (onCallback) {
      onCallback(callbackParams);
    }
  }, [onClick, onCallback, callbackParams]);

  if (isShowContentOnly) {
    return (
      <button
        className={cx('BtnReset', className)}
        disabled={isLoading || isDisabled}
        onClick={onClickBtn}
        type={type}
        {...rest}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      className={cx('Btn', className)}
      type={type}
      disabled={isLoading || isDisabled}
      onClick={onClickBtn}
      {...rest}
    >
      {children}
    </button>
  );
}

AppButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isShowContentOnly: PropTypes.bool,

  className: PropTypes.string,
  type: PropTypes.string,

  callbackParams: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),

  onCallback: PropTypes.func,
  onClick: PropTypes.func,

  children: PropTypes.node,
};

AppButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isShowContentOnly: false,

  className: '',
  type: 'button',

  callbackParams: null,

  onCallback: null,
  onClick: null,

  children: null,
};

export default AppButton;
